import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader } from "reactstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { timeAgo } from "../../Components/Common/TimeAgo";

const StyledInfoWindow = styled.div`
  width: 300px;
  background-color: white;
  cursor: pointer;
  color: black;

  // height: 250px;
  // border-radius: 5px;
  // border: 1px solid black;
  // position: relative;
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     bottom: -40px;
  //     left: 0;
  //     border: 20px solid black;
  //     border-color: white transparent transparent transparent;
  //     border-radius: 5px;
  //   }
`;

export const SiteInfoWindow = ({ info }) => {
  const [showExpiredModel, setShowExpiredModel] = useState(false);
  const [offlineDevices, setOfflineDevices] = useState([]);
  const redBoldStyle = {
    color: "red",
    fontWeight: "bold",
  };
  useEffect(() => {
    setShowExpiredModel(info.expired);
  }, [info]);
  useEffect(() => {
    const devices = [
      ...info.tempData.devices_used,
      ...info.windData.devices_used,
    ];

    const offlineDevs = devices.filter(
      (obj) => obj.device.status.toLowerCase() === "offline"
    );

    const uniqueOfflineDevs = offlineDevs.reduce(
      (accumulator, currentDevice) => {
        // Check if the device's _id is not already in the accumulator array
        if (
          !accumulator.some(
            (dev) => dev.device._id === currentDevice.device._id
          )
        ) {
          accumulator.push(currentDevice);
        }
        return accumulator;
      },
      []
    );

    setOfflineDevices(uniqueOfflineDevs);
  }, [info]);

  return (
    <>
      <StyledInfoWindow>
        <h4>
          {info?.type?.toUpperCase()} {info.siteInfo.name}
        </h4>
        <ul className="d-flex flex-column gap-2 mt-3">
          <li>
            <strong> Data Time :</strong>{" "}
            {timeAgo(info.siteInfo.updatedAt, info.siteInfo.updatedAt)}{" "}
            {showExpiredModel && <span style={redBoldStyle}> (Expired)</span>}
          </li>
          <li>
            <strong>Temperature: </strong>
            {info.tempData.avg_temp ? info.tempData.avg_temp : "N/A"} °
          </li>
          <li>
            <strong> Humidity:</strong>{" "}
            {info.tempData.avg_humi ? info.tempData.avg_humi : "N/A"} %
          </li>
          <li>
            <strong>Wind Speed: </strong>{" "}
            {info.windData.avg_speed ? info.windData.avg_speed : "N/A"} Km/h
          </li>
          <li>
            <strong> Wind Direction:</strong>{" "}
            {info.windData.avg_direction ? info.windData.avg_direction : "N/A"}
          </li>
          <li className="w-100 link-style">
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${info.siteInfo.location?.coordinates[0]},${info.siteInfo.location?.coordinates[1]}`}
              target="_blank"
              className="link-style"
              rel="noopener noreferrer"
            >
              <strong>
                [{info.siteInfo.location?.coordinates[0]},
                {info.siteInfo.location?.coordinates[1]}]
              </strong>
            </a>
          </li>
        </ul>
        {offlineDevices.length > 0 && (
          <>
            <p>
              <strong className="italic">
                Note: the following devices are{" "}
                <span style={{ color: "red" }}>Offline</span> :
              </strong>
            </p>
            <ul>
              {offlineDevices.map((dev) => (
                <li key={dev.device._id} className="link-style">
                  <Link
                    to={`/devices/list?deviceId=${dev.device.label}`}
                    className="link-style"
                  >
                    Device ID: {dev.device.devId}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </StyledInfoWindow>
    </>
  );
};
