import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

import ReactSelect from "react-select";
import styled from "styled-components";
import {
  prepareSelectOptions,
  reactSelectStyling,
} from "./Forms/FormikInputHelper";
import DatePicker from "../Common/DatePickerMonitor";
const Section = ({ setSelectedDeviceLabel, devicesOptions }) => {
  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Row lg={12}>
          <Col lg={4}>
            <div className="">
              {/* <h4 className="fs-16 mb-1">Monitor Your Devices!</h4> */}
              <div className="col-sm-auto">
                <div className="input-group">
                  <DatePicker />
                  <div className="input-group-text bg-primary border-primary text-white">
                    <i className="ri-calendar-2-line"></i>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4}></Col>
          <Col lg={4}>
            <div className="mt-3 mt-lg-0">
              <Row className="g-3 mb-0 align-items-center">
                {/* <div className="col-sm-auto"> */}
                <StyledFilterWrapper>
                  <ReactSelect
                    {...reactSelectStyling}
                    options={devicesOptions}
                    //options={field.selectOptions}
                    isSearchable={true}
                    placeholder={devicesOptions[0]?.label || "device"}
                    isClearable={true}
                    name={"search"}
                    onChange={(option) => {
                      setSelectedDeviceLabel(option?.label);
                      //check if the option is null
                    }}
                  />
                </StyledFilterWrapper>
                {/* put a selector here */}
                {/* </div> */}
              </Row>
            </div>
          </Col>
        </Row>
      </Row>
    </React.Fragment>
  );
};

export default Section;

const StyledFilterWrapper = styled.div`
  min-width: 400px;
  // flex: 1;
`;
