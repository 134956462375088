import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";
import moment from "moment";
export const useAlertsStore = create((set, get) => ({
  alerts: [],
  alertsByDates: [],
  mapAlerts: [],
  updatedAt: new Date().getTime(),
  isLoading: false,
  isError: false,
  error: undefined,
  filters: {
    code: "",
    type: "",
    dateFrom: "",
    dateTo: "",
    wilaya: "",
    devId: "",
    falsePositive: "false",
  },
  resultsCount: undefined,
  // Methods
  getAlerts: async (filters) => {
    set({ filters: filterNonFalseValues(filters) });
    const body = {
      ...filterNonFalseValues(filters),
      // search: search,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      page: filters?.page || 1,
      limit: filters?.perPage || 10,
    };

    try {
      set({ isLoading: true });
      let response = await axiosHelper.post("/system/alert/get", body);

      if (!response.result) {
        return;
      }
      let mapAlerts = response.mapAlerts;

      set({
        alerts: response.data,
        mapAlerts: mapAlerts,
        resultsCount: response.count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  createAlert: async (body) => {
    // body.lastOnline = new Date();
    set({ isLoading: true });
    try {
      let response = await axiosHelper.post("/system/alert/create", body);
      if (!response.result) {
        return;
      }
      get().getAlerts();
      return response.result;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },
  getAlertsByDates: async (body) => {
    set({ isLoading: true });
    try {
      let response = await axiosHelper.post(`/system/alert/get`, body);
      if (!response.result) {
        return;
      }

      set({
        alertsByDates: response.data,
        isLoading: false,
      });

      return response;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },
  deleteAlertRecord: async (id) => {
    // console.log(id)
    // set({ isLoading: true });
    try {
      let response = await axiosHelper.delete(`/system/alert/delete/${id}`);
      if (!response.result) {
        return;
      }
      // get().fetchTenants(get().filters);
      // get().getAlerts();
      set(
        (state) =>
          (state.alerts = state.alerts.filter((alert) => alert._id !== id))
      );

      return response;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },

  setToFalsePositive: async (id) => {
    // console.log(id)
    let url = `/system/alert/update/${id}`;
    // set({ isLoading: true });
    try {
      let response = await axiosHelper.put(url, {
        falsePositive: true,
      });
      if (!response.result) {
        return;
      }

      // get().getAlerts();
      set(
        (state) =>
          (state.alerts = state.alerts.map((alert) =>
            alert._id === id ? response.data : alert
          ))
      );
      console.log(get().alerts);
      return response.result;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },
  setToTruePositive: async (id) => {
    // console.log(id)
    let url = `/system/alert/update/${id}`;
    // set({ isLoading: true });
    try {
      let response = await axiosHelper.put(url, {
        falsePositive: false,
      });
      if (!response.result) {
        return;
      }
      // get().getAlerts();
      set(
        (state) =>
          (state.alerts = state.alerts.map((alert) =>
            alert._id === id ? response.data : alert
          ))
      );

      return response.result;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },

  setFilters: (filters) => {
    set({ filters: filterNonFalseValues(filters) });
  },

  // toggleFalsePositive: async () => {
  //   const body = {
  //     falsePositive: true,
  //   };

  //   try {
  //     set({ isLoading: true });
  //     let response = await axiosHelper.post("/system/alert/get", body);

  //     if (!response.result) {
  //       return;
  //     }
  //     // console.log(response.data);

  //     set({ alerts: response.data, isLoading: false });
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     set({ isLoading: false });
  //   }
  // },
  refreshAlerts: () => {
    //refresh alerts every minute

    setInterval(async () => {
      try {
        let response = await axiosHelper.post(
          "/system/alert/get",
          get().filter
        );

        if (!response.result) {
          return;
        }
        let mapAlerts = response.mapAlerts;
        // if (mapAlerts.length === 0) mapAlerts.push({});
        // mapAlerts.push({location:{
        //   coordinates: [0,0]}})
        set({
          alerts: response.data,
          mapAlerts: mapAlerts,
          resultsCount: response.count,
          isLoading: false,
        });
        set({ updatedAt: new Date().getTime() });
      } catch (error) {
        console.log(error);
      }
      //log
    }, 60000);
  },
}));

//TODO: add condition to check user logged in first
//refresh alerts
useAlertsStore.getState().refreshAlerts();
