import { create } from "zustand";
import { axiosHelper } from "./helpers";
import axios from "axios";

export const useNetworkStatus = create((set, get) => ({
  isOnline: true,

  // Methods
  checkNetwork: async () => {
    try {
      let response = await axiosHelper.get("/health");

      if (response.status === 200) set({ isOnline: true });
      else set({ isOnline: false });
    } catch (error) {
      console.log("Network check error:", error);

      // Check if the error is related to network issues
      if (
        error.message.includes("Network Error") ||
        error.code === "ECONNABORTED" ||
        error.code === "ECONNREFUSED" ||
        error.code === "EHOSTUNREACH"
      ) {
        set({ isOnline: false });
      } else {
        // Handle other types of errors if necessary
        console.error("Unexpected error:", error);
      }
    }
  },

  refreshNetwork: () => {
    // Refresh alerts every 5 seconds
    setInterval(async () => {
      try {
        let response = await axios.get("https://dgf.basseer.com/api/health");

        if (response.status === 200) set({ isOnline: true });
        else set({ isOnline: false });
      } catch (error) {
        // Check if the error is related to network issues
        if (
          error.includes("Network Error") ||
          error.code === "ECONNABORTED" ||
          error.code === "ECONNREFUSED" ||
          error.code === "EHOSTUNREACH"
        ) {
          set({ isOnline: false });
        } else {
          // Handle other types of errors if necessary
          console.error("Unexpected error:", error);
        }
      }
    }, 10000);
  },
}));

// Initialize network status check and periodic refresh
// useNetworkStatus.getState().checkNetwork();
useNetworkStatus.getState().refreshNetwork();
