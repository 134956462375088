import React, { useEffect, useState } from "react";
import InfoCard from "../InfoCard";
import { Card, CardBody, CardHeader, Row } from "reactstrap";
import ShowContact from "../ShowContact";
import imageForSite from "../../../../assets/images/template2dash.png";

const SiteCard = ({ info, series, alternative }) => {
  const data = {};
  const { siteInfo } = info;
  const [HumidityData, setHumidityData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [speedData, setSpeedData] = useState([]);
  const [showmod, setShowMod] = useState(false);
  const [flagged, setFlagged] = useState(false);
  const [flaggedBy, setFlaggedBy] = useState([]);
  useEffect(() => {}, [alternative]);
  useEffect(() => {
    if (series) {
      const updatedHumidityData = series
        .map((item) =>
          Math.round(parseFloat(item.humidity?.value || item?.humidity))
        )
        .reverse();
      const updatedTempData = series
        .map((item) =>
          Math.round(parseFloat(item?.temperature?.value || item?.temperature))
        )
        .reverse();
      const updatedSpeedData = series
        .map((item) =>
          Math.round(parseFloat(item?.speed?.value || item?.speed))
        )
        .reverse();
      setHumidityData(updatedHumidityData);
      setTempData(updatedTempData);
      setSpeedData(updatedSpeedData);
    }
  }, [series]);

  useEffect(() => {
    //add FDI danger boolean
    if (
      siteInfo?.isDangerous ||
      series[0]?.isDangerous ||
      series[0]?.status30.toLowerCase() === "danger" ||
      alternative?.status30.status.toLowerCase() === "danger" ||
      alternative?.statusFDI.level >= 3
    ) {
      const triggers = [];
      setFlagged(true);
      if (
        siteInfo?.isDangerous ||
        series[0]?.isDangerous ||
        alternative?.statusFDI.level >= 3
      ) {
        // ||alternative.isDangerous
        triggers.push("FDI");
      }
      if (
        series[0]?.status30.toLowerCase() === "danger" ||
        alternative.status30.status.toLowerCase() === "danger"
      ) {
        triggers.push("30%");
      }

      setFlaggedBy(triggers);
    } else {
      setFlagged(false);
    }
  }, [siteInfo, series, alternative]);
  return (
    <Card style={{ width: "100%", padding: 0 }}>
      <CardHeader className="p-0">
        <div
          style={{ backgroundColor: flagged ? "red" : "green", color: "white" }}
          className={`d-flex justify-content-between align-items-center  bg-gradient m-0 p-2`}
        >
          <div className="d-flex flex-column gap-2">
            <div className="card-title mb-0">
              <strong>VIGIE: {siteInfo.name}</strong>{" "}
              <span
                style={{ cursor: "pointer", border: "none" }}
                className="text-decoration-underline bg-transparent "
                onClick={() => {
                  setShowMod((mod) => !mod);
                }}
              >
                (contact)
              </span>
            </div>
            <div>
              {siteInfo.wilaya} - {siteInfo.region}
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="card-title mb-0">ETAT INCENDIE </div>
            <div>
              <strong>{"AUCUN"}</strong>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Row
          className="d-flex row-cols-2 align-items-end justify-content-start "
          style={{ marginBottom: 0 }}
        >
          <Card style={{ marginBottom: 0 }} className="">
            <CardBody className="px-0">
              <h2>
                {" "}
                Loi 30% :{" "}
                <span
                  className={`${
                    flaggedBy.includes("30%") ? "text-danger" : "text-success"
                  } `}
                >
                  <strong>
                    {series[0]?.status30
                      ? series[0]?.status30
                      : alternative?.status30.status}
                  </strong>
                </span>{" "}
              </h2>
            </CardBody>
          </Card>
          <Card style={{ marginBottom: 0 }} className="">
            <CardBody className="px-0">
              <h2>
                {" "}
                Loi FDI :{" "}
                <span
                  className={`${
                    flaggedBy.includes("FDI") ? "text-danger" : "text-success"
                  } `}
                >
                  {series[0]?.statusFDI
                    ? series[0]?.statusFDI
                    : alternative?.statusFDI.status}
                </span>{" "}
              </h2>
            </CardBody>
          </Card>

          {/* <h4 className={`${flaggedBy.includes("30%")?"text-danger":"text-success"} `}>30% : {siteInfo.status30}</h4>

<h4 className={`${flaggedBy.includes("FDI")?"text-danger":"text-success"}`}>FDI : {siteInfo.statusFDI}</h4> */}
        </Row>
        <div className="d-flex flex-row gap-2 justify-content-between  align-items-center">
          <div
            style={{ width: "250px" }}
            className="d-flex flex-column gap-4 flex-grow-1"
          >
            <InfoCard
              tempData={tempData.length > 0 ? tempData : null}
              avghumi={null}
              avgspeed={null}
              avgtemp={
                tempData.length > 0
                  ? tempData[tempData.length - 1]
                  : alternative?.temperature?.value || alternative?.temperature
              }
            />
            <InfoCard
              avgspeed={null}
              avgtemp={null}
              humidityData={HumidityData.length > 0 ? HumidityData : null}
              avghumi={
                HumidityData.length > 0
                  ? HumidityData[HumidityData.length - 1]
                  : alternative?.humidity?.value || alternative?.humidity
              }
            />
            <InfoCard
              avgspeed={
                speedData.length > 0
                  ? speedData[speedData.length - 1]
                  : alternative?.speed?.value || alternative?.speed
              }
              avgDir={
                series?.length > 0
                  ? series[speedData.length - 1]?.direction
                  : alternative?.direction
              }
              windData={speedData.length > 0 ? speedData : null}
              avgtemp={null}
              avghumi={null}
            />
          </div>
          <div
            className="image flex-grow"
            style={{
              height: "300px",
              width: "50%",
              overflow: "hidden",
              background: "red",
            }}
          >
            <img
              src={imageForSite}
              alt="SITE PICTURE"
              style={{
                height: "100%",
                background: "red",
                objectFit: "cover",
                width: "100%",
              }}
            />
          </div>
        </div>
      </CardBody>
      {showmod && (
        <ShowContact
          showContactModule={showmod}
          contact={siteInfo.contact ? siteInfo.contact : "057151505"}
          handleMarkerClick={() => setShowMod((mod) => !mod)}
        />
      )}
    </Card>
  );
};

export default SiteCard;
