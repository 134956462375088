import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Widget from "./Widget";
import { t } from "i18next";
import { BrushChart, BrushChart1 } from "./Charts/BrushChart";
import { StackedHorizontalBarChart } from "./Charts/Echarts";
import Vectormap from "./Maps/Vector";
import Section from "./Section";
import { useDashboardStore } from "../../../stores/DashboardOne";
import "./extra.scss";
import { useDatePickerStore } from "../../../stores/dashboardOneDates";
import { useAlertsStore } from "../../../stores/Alerts";
import CountUp from "react-countup";
import HighestWidget from "./HighestWidget";
import FlameSVG from "../../../assets/imgs/flame.svg";
import moment from "moment";
import {
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useUpdatedStore } from "../../../stores/UpdatedStore";
export default function DashboardOne() {
  const title = "BASSEERFIRE | Dashboard1";
  document.title = title;
  const { isLoading } = useDashboardStore((state) => state);
  const { getAlerts, mapAlerts, updatedAt } = useAlertsStore((state) => state);
  const { selectedDates } = useDatePickerStore((state) => state);
  const { getWind, getTemperature } = useDashboardStore((state) => state);
  const [mapMode, setMapMode] = useState("normal");
  const { lastUpdate } = useUpdatedStore((state) => state);

  const [layer, setLayer] = useState(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  );
  useEffect(() => {
    // Fetch data when selectedDates changes
    if (selectedDates.length === 2) {
      Promise.all([
        getAlerts({
          dateFrom: selectedDates[0],
          dateTo: selectedDates[1],
        }),
        // getWind({ selectedDates }),
        // getTemperature({ selectedDates }),
      ]).catch((error) => {
        // Handle errors here
        console.error(error);
      });
    }
  }, [selectedDates]);
  useEffect(() => {
    if (mapMode === "normal") {
      setLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    } else {
      setLayer("https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}");
    }
  }, [mapMode]);

  const widgetsData = [
    {
      label: "Alertes Aujourd’hui",
      prefix: "",
      suffix: "",
      counter: mapAlerts.length || 0,
    },
  ];
  // if(isLoading) return (<div>Is Loading...</div>)
  return (
    <React.Fragment>
      <Container fluid>
        <small className="text-muted  mb-2">
          Last Update: {moment(updatedAt).format("YYYY-MM-DD hh:mm:ss")}
        </small>
        <Section />

        <Row>
          <Col className="order-md-1" xl={6}>
            <Card style={{ height: "96.5%" }}>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0"> Surveillance </h4>
                  <div className="">
                    <ButtonGroup>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="button" className="btn btn-light">
                          Map Mode <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => setMapMode("normal")}>
                            Normal
                          </DropdownItem>
                          <DropdownItem onClick={() => setMapMode("satellite")}>
                            Satellite
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </ButtonGroup>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div
                  id="world-map-line-markers"
                  data-colors='["--vz-light"]'
                  style={{ height: "350px", position: "relative" }}
                >
                  <Vectormap
                    mapMode={mapMode}
                    layer={layer}
                    value="world-mill"
                    width="300"
                    color="rgb(212, 218, 221)"
                  />
                </div>
                <div className="px-2 py-2 mt-5 d-flex align-items-start justify-content-start gap-2">
                  <div>
                    <div className="d-flex align-items-start justify-content-start gap-2">
                      <p className="marker-gray  "></p>

                      <h5>Hors Ligne</h5>
                    </div>
                    <div className="d-flex align-items-start justify-content-start gap-2">
                      <p className="marker-green "></p>

                      <h5>Tous va bien</h5>
                    </div>
                    <div className="d-flex align-items-start justify-content-start gap-2">
                      <p className="marker-orange "></p>

                      <h5>
                        Semi EnLigne{" "}
                        <small>
                          (un ou plusieurs appareils sont hors ligne)
                        </small>
                      </h5>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex align-items-start justify-content-start gap-2">
                      <p className="marker-red "></p>

                      <h5>Danger</h5>
                    </div>
                    <div className="d-flex align-items-start justify-content-start gap-2">
                      <p className="marker-flame "></p>

                      <h5>Alerte </h5>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col className="order-md-2" xl={6}>
            <Row className="">
              <Col lg={6} md={12}>
                {widgetsData.map((data, index) => (
                  <Widget
                    data={data}
                    key={index}
                    data-aos="zoom-in-up"
                    data-aos-delay={index * 100}
                  />
                ))}
              </Col>
              <Col lg={6} md={12}>
                <HighestWidget />
              </Col>
            </Row>

            <Row className="row">
              <Col lg={6} md={12}>
                <Card className="col me-3">
                  <CardHeader>
                    <h4 className="card-title mb-0">Temperature</h4>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <BrushChart
                        selectedDates={selectedDates}
                        dataColors='["--vz-danger"]'
                      />
                    </div>
                    {/* <div>
                    <BrushChart1 dataColors='["--vz-info"]' />
                  </div> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6} md={12}>
                <Card className="col">
                  <CardHeader>
                    <h4 className="card-title mb-0">Comparaison</h4>
                  </CardHeader>
                  <div className="card-body">
                    <StackedHorizontalBarChart dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]' />
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
