import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Marker,
  MapContainer,
  TileLayer,
  Polygon,
  Circle,
  Popup,
  Tooltip,
} from "react-leaflet";
import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import BreadCrumb from "./BreadCrumb";
import { useDashboardThreeStore } from "../../../stores/DahboardThree";
import { divIcon } from "leaflet";
import "./extra.scss";
import Loader from "../../../Components/Common/Loader";
import moment from "moment";

const legendItemStyle = {
  display: "flex",
  alignItems: "center",
  margin: "5px 0",
};

const legendIconStyle = {
  width: "20px",
  height: "20px",
  marginRight: "5px",
};

const LegendFDI = () => {
  return (
    //, backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px', borderRadius: '5px'
    <Card
      className="legend"
      style={{
        position: "absolute",
        zIndex: "900",
        bottom: "0",
        left: "20px",
        opacity: "0.8",
      }}
    >
      <CardHeader style={{ margin: 0 }}>
        <div>FDI Legend</div>
      </CardHeader>

      <CardBody>
        <div style={legendItemStyle}>
          <div className="low-icon" style={{ ...legendIconStyle }}></div>
          <div>Low</div>
        </div>
        <div style={legendItemStyle}>
          <div className="moderate-icon" style={{ ...legendIconStyle }}></div>
          <div>Moderate</div>
        </div>
        <div style={legendItemStyle}>
          <div className="high-icon" style={{ ...legendIconStyle }}></div>
          <div>High</div>
        </div>
        <div style={legendItemStyle}>
          <div className="very-high-icon" style={{ ...legendIconStyle }}></div>
          <div>Very High</div>
        </div>
        <div style={legendItemStyle}>
          <div className="extreme-icon" style={{ ...legendIconStyle }}></div>
          <div>Extreme</div>
        </div>
        <div style={legendItemStyle}>
          <div
            className="catastrophic-icon"
            style={{ ...legendIconStyle, animation: "none" }}
          ></div>
          <div>Catastrophic</div>
        </div>
      </CardBody>
      {/* Add more status as needed */}
    </Card>
  );
};
const Legend30 = () => {
  // Similar structure for the legend for 30% formula
  // Customize the styles and contents as needed
  return (
    <Card
      className="legend"
      style={{
        position: "absolute",
        zIndex: "900",
        bottom: "0",
        left: "20px",
        opacity: "0.8",
      }}
    >
      <CardHeader style={{ margin: 0 }}>
        <div>30% Legend</div>
      </CardHeader>

      <CardBody>
        <div style={legendItemStyle}>
          <div className="danger-icon" style={{ ...legendIconStyle }}></div>
          <div>Danger</div>
        </div>
        <div style={legendItemStyle}>
          <div className="online-icon" style={{ ...legendIconStyle }}></div>
          <div>Online</div>
        </div>
      </CardBody>
      {/* Add more status as needed */}
    </Card>
  );
};
const Dashboard_Three = () => {
  const [mapMode, setMapMode] = useState("normal");
  const [fullscreen, setFullscreen] = useState(false);
  const handleFullscreen = () => {
    setFullscreen(!fullscreen);
  };
  const mapRef = useRef(null);
  const title = "BASSEERFIRE | Dashboard3";
  document.title = title;
  const { layoutModeType } = useSelector((state) => ({
    layoutModeType: state.Layout.layoutModeType,
  }));

  const [mapType, setMapType] = useState("satellite");
  const [layer, setLayer] = useState(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  );
  const [selectedFormula, setSelectedFormula] = useState({
    value: "FDI",
    label: "formule FDI ",
  });
  const [openMarkerId, setOpenMarkerId] = useState(null);
  const [selectedForecast, setSelectedForecast] = useState({
    value: "Today",
    label: "Today",
  });

  const {
    getRegionsForecasts,
    isLoading,
    regionForecasts,
    updatedAt,
    updateTime,
  } = useDashboardThreeStore((state) => state);
  const testPolygon = []; // Define your testPolygon here

  const defaultProps = {
    center: {
      lat: 30.0444,
      lng: 31.2357,
    },
    algeria: {
      lat: 36.37377988878743,
      lng: 3.8951729813480367,
    },
    zoom: 7,
  };

  useEffect(() => {
    getRegionsForecasts && getRegionsForecasts();
    const intervalId = setInterval(() => {
      getRegionsForecasts && getRegionsForecasts();
      updateTime();
    }, 300000); // 5 minutes in milliseconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {}, [regionForecasts]);

  useEffect(() => {
    if (mapMode === "normal") {
      setLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    } else {
      setLayer("https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}");
    }
  }, [mapMode]);
  const chooseSiteClassName = (classification) => {
    if (selectedFormula.value === "30%") {
      // Use the siteClassification30 property when the classification type is "30%"
      if (classification?.toLowerCase() === "danger") {
        return "danger-icon";
      } else if (classification?.toLowerCase() === "good") {
        return "online-icon";
      }
    } else {
      // Use the siteClassificationFDI property when the classification type is not "30%"
      if (classification === "low") {
        return "low-icon";
      } else if (classification === "moderate") {
        return "moderate-icon";
      } else if (classification === "high") {
        return "high-icon";
      } else if (classification === "very high") {
        return "very-high-icon";
      } else if (classification === "extreme") {
        return "extreme-icon";
      } else if (classification === "catastrophic") {
        return "catastrophic-icon";
      }
      // Add more conditions for other classifications as needed
    }
  };

  const [markersData, setMarkersData] = useState([]);
  useEffect(() => {
    const updatedMarkersData = regionForecasts.map((item) => {
      let status;
      let temperature;
      let windSpeed;
      let precip;
      let windDir;
      let humidity;
      let forecastValue = selectedForecast.value;
      let date;
      if (selectedFormula.value === "FDI") {
        if (forecastValue === "Last Updated") {
          status = item.FDI?.status;
          temperature = isNaN(item.temperature?.value)
            ? item.temperature
            : item.temperature?.value;
          windSpeed = isNaN(item.windSpeed?.value)
            ? item.windSpeed
            : item.windSpeed?.value;
          precip = isNaN(item.percipitation?.value)
            ? item.percipitation
            : item.percipitation?.value;
          windDir = item.windDirectionDegrees;
          humidity = isNaN(item.humidity?.value)
            ? item.humidity
            : item.humidity?.value;
          date = item.date;
        } else if (forecastValue === "Today") {
          status = item.FDI_0?.status;
          temperature = item.temperature_0;
          windSpeed = item.windSpeed_0;
          precip = item.percipitation_0;
          windDir = item.windDirectionDegrees_0;
          humidity = item.humidity_0;
          date = item.date_0;
        } else if (forecastValue === "next 24h") {
          status = item.FDI_24?.status;
          temperature = item.temperature_24;
          windSpeed = item.windSpeed_24;
          precip = item.percipitation_24;
          windDir = item.windDirectionDegrees_24;
          humidity = item.humidity_24;
          date = item.date_24;
        } else if (forecastValue === "next 48h") {
          status = item.FDI_48?.status;
          temperature = item.temperature_48;
          windSpeed = item.windSpeed_48;
          precip = item.percipitation_48;
          windDir = item.windDirectionDegrees_48;
          humidity = item.humidity_48;
          date = item.date_48;
        }
      } else if (selectedFormula.value === "30%") {
        if (forecastValue === "Last Updated") {
          status = item.rule30?.status;
          temperature = isNaN(item.temperature?.value)
            ? item.temperature
            : item.temperature?.value;
          windSpeed = isNaN(item.windSpeed?.value)
            ? item.windSpeed
            : item.windSpeed?.value;
          precip = isNaN(item.percipitation?.value)
            ? item.percipitation
            : item.percipitation?.value;
          windDir = item.windDirectionDegrees;
          humidity = isNaN(item.humidity?.value)
            ? item.humidity
            : item.humidity?.value;
          date = item.date;
        } else if (forecastValue === "Today") {
          status = item.rule30_0?.status;
          temperature = item.temperature_0;
          windSpeed = item.windSpeed_0;
          precip = item.percipitation_0;
          windDir = item.windDirectionDegrees_0;
          humidity = item.humidity_0;
          date = item.date_0;
        } else if (forecastValue === "next 24h") {
          status = item.rule30_24?.status;
          temperature = item.temperature_24;
          windSpeed = item.windSpeed_24;
          precip = item.percipitation_24;
          windDir = item.windDirectionDegrees_24;
          humidity = item.humidity_24;
          date = item.date_24;
        } else if (forecastValue === "next 48h") {
          status = item.rule30_48?.status;
          temperature = item.temperature_48;
          windSpeed = item.windSpeed_48;
          precip = item.percipitation_48;
          windDir = item.windDirectionDegrees_48;
          humidity = item.humidity_48;
          date = item.date_48;
        }
      }

      return {
        code: item.code,
        position: item.coordinates.coordinates,
        icon: divIcon({
          iconSize: [20, 20],
          iconAnchor: [15, 15],
          className: chooseSiteClassName(status), // You can define your class here
        }),
        popupContent: {
          temperature,
          humidity,
          windSpeed,
          precip,
          windDir,
          date,
          status,
        },
      };
    });

    setMarkersData(updatedMarkersData);
  }, [regionForecasts, selectedForecast.value, selectedFormula.value]);

  // useEffect(()=>{
  //
  // },[markersData])
  if (markersData.length === 0 || isLoading) {
    return (
      <div
        style={{ height: "70vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <BreadCrumb
        title={title}
        setSelectedFormula={setSelectedFormula}
        selectedFormula={selectedFormula}
        selectedForecast={selectedForecast}
        setSelectedForecast={setSelectedForecast}
      />
      <small className="text-muted  mb-2">
        Last Update: {moment(updatedAt).format("YYYY-MM-DD hh:mm:ss")}
      </small>
      <div
        style={{ position: "relative", height: "70vh", width: "100%" }}
        className={`${
          layoutModeType === "dark" && mapMode === "normal"
            ? "leaflet-darkmode"
            : ""
        } map-main relative`}
      >
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            zIndex: "900",
          }}
          className=""
        >
          <ButtonGroup>
            {/* <button onClick={handleFullscreen}>{fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}</button> */}

            <UncontrolledDropdown>
              <DropdownToggle tag="button" className="btn btn-light">
                Map Mode <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setMapMode("normal")}>
                  Normal
                </DropdownItem>
                <DropdownItem onClick={() => setMapMode("satellite")}>
                  Satellite
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ButtonGroup>
        </div>
        <MapContainer
          className="leaflet-bounds"
          ref={mapRef}
          style={{ height: "100%" }}
          center={[36.54190058009416, 3]}
          zoom={defaultProps.zoom}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url={layer}
          />
          {markersData &&
            markersData.map((data) => (
              <Marker key={data.code} position={data.position} icon={data.icon}>
                <Tooltip direction="top" offset={[-6, -20]} opacity={1}>
                  <div style={{ fontSize: "17px" }}>
                    {selectedFormula.value} : {data.popupContent.status}
                  </div>
                </Tooltip>
                <Popup>
                  <div>
                    <h5>
                      <strong>Etat : </strong>{" "}
                      <span>{data.popupContent.status}</span> /{" "}
                      <span>{data.popupContent.date} </span>
                    </h5>
                    <h5>Metriques</h5>
                    <ul>
                      <li>
                        <strong>Temp:</strong>{" "}
                        {data.popupContent.temperature?.value}
                      </li>
                      <li>
                        <strong>Humidity:</strong>{" "}
                        {data.popupContent.humidity?.value}
                      </li>
                      <li>
                        <strong>Wind Speed:</strong>{" "}
                        {data.popupContent.windSpeed?.value}
                      </li>
                      <li>
                        <strong>Precipitation:</strong>{" "}
                        {data.popupContent.precip?.value}
                      </li>
                      <li>
                        <strong>Wind Direction:</strong>{" "}
                        {data.popupContent.windDir}
                      </li>
                    </ul>
                  </div>
                </Popup>
              </Marker>
            ))}
          {selectedFormula.value === "FDI" ? <LegendFDI /> : <Legend30 />}
        </MapContainer>
      </div>
    </div>
  );
};

export default Dashboard_Three;
