import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";
import { useAuth } from "../stores";
import {
  getFirstAccessibleRoute,
  pageIsAllowed,
} from "../Components/Common/LevelOf";

// Helper function to get the first accessible route

const Index = () => {
  const user = useAuth((state) => state.user);

  const firstAccessibleRoute = getFirstAccessibleRoute(
    user,
    authProtectedRoutes
  );

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {/* Allow access to acme-challenge path without authentication */}
          <Route path="/.well-known/acme-challenge/*" element={<div />} />
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected id={route.id} level={route.level}>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </AuthProtected>
              }
              key={idx}
              exact={true}
            />
          ))}
          {/* Dynamic Default Redirection */}
          <Route path="/" element={<Navigate to={firstAccessibleRoute} />} />
          <Route path="*" element={<Navigate to={firstAccessibleRoute} />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
