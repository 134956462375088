import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Button, Input } from "reactstrap";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

export const AlertsTableFilters = ({
  filters,
  onFilterButtonClick,
  onCancelButtonClick,
}) => {
  // const [resetFilter, setResetFilters] = useState();

  return (
    <div className="pb-3 d-flex gap-2 align-items-center">
      <h5 className="m-0">{t("Filters")}</h5>

      {filters &&
        filters.map((filter) => {
          switch (filter.type) {
            case "date":
              return (
                // <Flatpickr
                //   data-disable-time
                //   key={filter.name}
                //   id={filter.name}
                //   name={filter.name}
                //   placeholder={filter.colName}
                //   className=" form-control"
                //   // value={"date"}
                //   onChange={filter.onChange}
                // />
                <span key={filter.name}></span>
              );
              break;

            default:
              return (
                <Input
                  key={filter.name}
                  id={filter.name}
                  name={filter.name}
                  placeholder={filter.colName}
                  className={`${filter.className}`}
                  onChange={filter.onChange}
                  value={filter.value || undefined}
                  type={filter.type || "text"}
                >
                  {filter.selectOptions &&
                    filter.selectOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        defaultValue={option.selected ? true : false}
                        // defaultValue={'true'}
                      >
                        {t(option.displayText)}{" "}
                        {option.value === "" && filter.colName}
                      </option>
                    ))}
                </Input>
              );
              break;
          }
        })}
      <Button color="secondary" onClick={onFilterButtonClick}>
        {t("Filter")}
      </Button>
      <Button color="danger" onClick={onCancelButtonClick}>
        {t("Cancel")}
      </Button>
    </div>
  );
};
