import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import Select from "react-select";

const BreadCrumb = ({
  title,
  selectedFormula,
  setSelectedFormula,
  selectedForecast,
  setSelectedForecast,
}) => {
  const [classificationOptions, setClassificationOptions] = useState([
    { value: "30%", label: "Formule 30%" },
    { value: "FDI", label: "Formule FDI" },
  ]);

  const forecastOptions = [
    { value: "Last Updated", label: "Last Updated" },
    { value: "Today", label: "Today" },
    { value: "next 24h", label: "Next 24 Hours" },
    { value: "next 48h", label: "Next 48 Hours" },
  ];

  useEffect(() => {
    // Handle any logic when the selected classification type changes
  }, [selectedFormula]);

  function handleFormulaChange(selectedFormula) {
    setSelectedFormula(selectedFormula);
  }

  function handleForecastChange(selectedForecast) {
    setSelectedForecast(selectedForecast);
  }

  return (
    <React.Fragment>
      <Row className="align-items-center mb-3 ">
        {/* Title */}
        <Col xs={12} lg={4}>
          <h4 className="mb-sm-0  ">{t(title)}</h4>
        </Col>

        {/* Formula Selector */}
        <Col style={{ zIndex: "1000" }} xs={12} lg={4}>
          {/* <h4>
            <strong>FORMULA:</strong>
          </h4> */}
          <Select
            value={selectedFormula}
            isMulti={false}
            onChange={handleFormulaChange}
            options={classificationOptions}
          />
        </Col>

        {/* Forecast Selector */}
        <Col style={{ zIndex: "1000" }} xs={12} lg={4}>
          {/* <h4>
            <strong>FORECAST:</strong>
          </h4> */}
          <Select
            value={selectedForecast}
            isMulti={false}
            onChange={handleForecastChange}
            options={forecastOptions}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
