import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { WidgetsCharts } from "./WidgetCharts";
import "./extrad2.scss";
const InfoCard = ({
  avgDir,
  avgtemp,
  avghumi,
  avgspeed,
  windData,
  tempData,
  humidityData,
}) => {
  const item = {
    id: 1,

    label: "Wind",
    value: "120kmh",
    change: "+13.11%",
    changeClass: "success",
    chartsColor: "#0ab39c",
    series: [
      {
        name: "Wind",
        data: [85, 68, 35, 90, 8, 11, 26, 54],
      },
    ],
  };
  const [series, setSeries] = useState([]);
  const [graphColor, setGraphColor] = useState("#0ab39c");
  useEffect(() => {
    if (windData) {
      setSeries([
        {
          name: "Wind",
          data: windData,
        },
      ]);
    } else if (tempData) {
      setSeries([
        {
          name: "Temperature",
          data: tempData,
        },
      ]);
    } else if (humidityData) {
      setSeries([
        {
          name: "Humidity",
          data: humidityData,
        },
      ]);
    }
  }, [humidityData || tempData || windData]);
  useEffect(() => {
    //
    if (series[0]?.name === "Humidity") {
      if (series[0]?.data[series[0].data.length - 1] <= 30) {
        setGraphColor("#b34b0a");
      } else {
        setGraphColor("#0ab39c");
      }
    } else {
      if (series[0]?.data[series[0].data.length - 1] >= 30) {
        setGraphColor("#b34b0a");
      } else {
        setGraphColor("#0ab39c");
      }
    }
  }, [series]);

  return (
    <Card
      style={{ marginTop: "5px", marginBottom: 0, width: "100%" }}
      className="align-content-end"
    >
      <CardBody style={{ width: "100%" }}>
        <Row className=" d-flex justify-content-between g-0">
          <Col lg={6} xs={6} className="">
            {/* <h4 className="fs-14">{avgtemp!==null ? "Temperature":""} {avgspeed!==null ? "windSpeed":""} {avghumi!==null ? "Humidity":""}</h4> */}
            {avgtemp !== null && (
              <h2
                className={`mt-2 ${parseInt(avgtemp) >= 30 && "text-danger"}`}
              >
                {avgtemp} °
              </h2>
            )}
            {avghumi !== null && (
              <h2 className={`mt-2 ${parseInt(avghumi) < 30 && "text-danger"}`}>
                {avghumi} %
              </h2>
            )}
            {avgspeed !== null && (
              <h3
                className={`mt-2 ${parseInt(avgspeed) >= 30 && "text-danger"}`}
              >
                {avgspeed}{" "}
                <span style={{ fontSize: "13px" }}> Kmh ({avgDir})</span>
              </h3>
            )}
            {/* <h4 className="mt-2">{avgtemp!==null ? avgtemp:null} {avgspeed !==null ? avgspeed:null} {avghumi!==null ? avghumi:null}</h4> */}
            {/* <p className={"fs-13 fw-medium mb-0 text-" + item.changeClass}>{item.change}</p> */}
          </Col>
          <Col
            className="webkitalign"
            xs={5}
            style={{ textAlign: "-webkit-right;" }}
          >
            <div className="apex-charts crypto-widget" dir="ltr">
              <WidgetsCharts
                seriesData={series || item.series}
                chartsColor={graphColor}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InfoCard;
