/* global google */

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { RenderFormikInput } from "./../../Components/Common/Forms/FormikInputHelper";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useDevicesStore } from "../../stores/Devices";
import _ from "lodash";
import { useAlertsStore } from "../../stores/Alerts";
import { useWilayasStore } from "../../stores/Wilayas";
import { convertToBase64 } from "../../Components/Common/convertToBase64";
export const AddAlert = ({ showAddAlertModal, toggleAddAlertModal }) => {
  const { isLoading, getDevices, devices } = useDevicesStore((state) => state);
  const { createAlert } = useAlertsStore((state) => state);
  useEffect(() => {
    getDevices && getDevices();
  }, []);

  const [selectedAlertType, setSelectedAlertType] = useState("");
  const [selectedObservationType, setSelectedObservationType] = useState("");
  const [selectedFormationType, setSelectedFormationType] = useState("");
  const [selectedMeansType, setSelectedMeansType] = useState("");
  const [selectedDevId, setSelectedDevId] = useState("");
  const [devIds, setDevIds] = useState([]);
  const [selectedWilaya, setSelectedWilaya] = useState("");
  const [wilayaList, setWilayaList] = useState([]);

  const [imageSmall, setImageSmall] = useState();
  const [imageNormal, setImageNormal] = useState();
  const { getWilayas, wilayas } = useWilayasStore((state) => state);

  useEffect(() => {
    getWilayas && getWilayas();
  }, []);

  useEffect(() => {
    const neededWilayaAttributes = wilayas.map((wilaya) => {
      return {
        label: `${wilaya.wilayaId}-${wilaya.name}`,
        value: wilaya._id,
      };
    });

    setWilayaList(neededWilayaAttributes);
  }, [wilayas]);

  const formik = useFormik({
    initialValues: {
      devId: "",
      type: "",
      wilaya: "",
      daira: "",
      commune: "",
      place: "--",
      start: "",
      end: "",
      typeFormation: "",
      observation: "",
      means: "",
      photo: "",
      lat: 0,
      lng: 0,
    },
    validationSchema: Yup.object({
      devId: Yup.string().required(t("Required")),
      observation: Yup.string().required(t("Required")),
      means: Yup.string().required(t("Required")),
      type: Yup.string().required(t("Required")),
      typeFormation: Yup.string().required(t("Required")),
      photo: Yup.string().optional(),
      start: Yup.date().required(t("Required")),
      end: Yup.date().required(t("Required")),

      // location: Yup.array().required(t("Required")),
      wilaya: Yup.string().required(t("Required")),
      place: Yup.string().required(t("Required")),

      daira: Yup.string().required(t("Required")),
      commune: Yup.string().required(t("Required")),
      lat: Yup.string().required(t("Required")),
      lng: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      //

      const device = devices.filter(
        (device) => device.devId === values.devId
      )[0]._id;
      //

      const body = {
        ...values,
        location: [+values.lat, +values.lng],
        device: device,
        photo: {
          normal: imageNormal,
          small: imageSmall,
        },
      };

      // omit lat lng from body
      const result = await createAlert(
        _.omit(body, ["lat", "lng", "region", "devId"])
      );

      if (result) {
        formik.resetForm();
        toggleAddAlertModal();
        resetStates();
      }
    },
  });
  function resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });
  }
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    const smallImage = await resizeImage(base64, 200, 150);
    const normalImage = await resizeImage(base64, 900, 630);

    setImageNormal(normalImage);
    setImageSmall(smallImage);
  };
  const fieldsToRender = [
    {
      fieldName: "devId",
      label: "Device Id",
      type: "select",
      placeholder: t("Select"),

      options: devIds,
      value: selectedDevId,
      onChange: (option) => {
        setSelectedDevId(option);
      },
    },
    {
      fieldName: "type",
      label: "Type",
      placeholder: t("Select"),
      type: "select",
      options: [
        { value: "smoke", label: "smoke" },
        { value: "fire", label: "fire" },
      ],
      value: selectedAlertType,
      onChange: (option) => {
        setSelectedAlertType(option);
      },
    },
    {
      fieldName: "observation",
      label: "Observation",
      placeholder: t("Select"),
      type: "select",
      options: [
        { value: "En Cours", label: "En Cours" },
        { value: "Reste Fumerons", label: "Reste Fumerons" },
      ],
      value: selectedObservationType,
      onChange: (option) => {
        setSelectedObservationType(option);
      },
    },
    {
      fieldName: "typeFormation",
      label: "TypeFormation",
      placeholder: t("Select"),
      type: "select",
      options: [
        { value: "Broussaille", label: "Broussaille" },
        { value: "Maquis", label: "Maquis" },
      ],
      value: selectedFormationType,
      onChange: (option) => {
        setSelectedFormationType(option);
      },
    },
    {
      fieldName: "means",
      label: "Means",
      placeholder: t("Select"),
      type: "select",
      options: [
        { value: "PC", label: "PC" },
        { value: "BMF", label: "BMF" },
      ],
      value: selectedMeansType,
      onChange: (option) => {
        setSelectedMeansType(option);
      },
    },

    {
      fieldName: "wilaya",
      label: "Wilaya",
      placeholder: t("Select"),
      type: "select",
      options: wilayaList,
      value: selectedWilaya,
      onChange: (option) => {
        setSelectedWilaya(option);
      },
    },
    {
      fieldName: "start",
      label: "Debut",
      type: "datetime-local",
    },
    {
      fieldName: "end",
      label: "Fin",
      type: "datetime-local",
    },

    { fieldName: "daira", label: "Daira", fullWidth: false },
    { fieldName: "commune", label: "Commune", fullWidth: false },
    { fieldName: "place", label: "Place", fullWidth: false },

    { fieldName: "lat", label: "Latitude", fullWidth: false },
    {
      fieldName: "lng",
      label: "Longitude",
      fullWidth: false,
      inputProps: {
        onBlur: () => {
          const lat = formik.values.lat;
          const lng = formik.values.lng;

          if (lat && lng) {
            setMarkerPosition({
              lat: +lat,
              lng: +lng,
            });
          }
        },
      },
    },
  ];
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: "YOUR_API_KEY",
  });
  const center = {
    lat: -3.745,
    lng: -38.523,
  };
  const [markerPosition, setMarkerPosition] = useState(center);

  const resetStates = () => {
    setSelectedAlertType(null);
    setSelectedFormationType(null);
    setSelectedMeansType(null);
    setSelectedObservationType(null);

    setMarkerPosition(center);
  };
  useEffect(() => {
    const devIds = devices.map((device) => {
      return { label: device.devId, value: device.devId };
    });

    setDevIds(devIds);
  }, [devices]);

  return (
    <Modal isOpen={showAddAlertModal} toggle={toggleAddAlertModal}>
      <ModalHeader toggle={toggleAddAlertModal}>
        {t("Add New Alert")}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} className="d-flex flex-wrap">
          {fieldsToRender.map((field) => (
            <div
              key={field.fieldName}
              className={`flex-fill mb-2 ${field.fullWidth ? "w-100" : ""}`}
              style={field.fullWidth ? {} : { width: "48%", marginRight: 2 }}
            >
              {RenderFormikInput(formik, field)}
            </div>
          ))}

          <Label htmlFor={"image"} className="w-100 form-label mt-1">
            {t("Image")}
          </Label>
          <input
            style={{
              padding: "0.5rem",
              // border: "1px black solid",
              width: "100%",
            }}
            className="bg-gradient rounded-3 "
            type="file"
            accept="image/*"
            onChange={(e) => handleFileUpload(e)}
          />
          <Label htmlFor={"location"} className="w-100 form-label mt-1">
            {t("Location")}
          </Label>

          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "200px",
            }}
            center={center}
            zoom={10}
            // onLoad={onLoad}
            // onUnmount={onUnmount}
          >
            <Marker
              position={markerPosition}
              // onClick={() => {
              //   handleMarkerClick(markerData);
              // }}
              draggable={true}
              onDragEnd={(info) => {
                const lat = info.latLng.lat();
                const lng = info.latLng.lng();
                setMarkerPosition({
                  lat: lat,
                  lng: lng,
                });
                formik.setFieldValue("lat", lat);
                formik.setFieldValue("lng", lng);
              }}
            />
          </GoogleMap>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          onClick={() => formik.handleSubmit()}
          color="success"
          // onClick={}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size={"sm"} /> : <span>{t("Add")}</span>}
        </Button>
        <Button
          color="danger"
          onClick={() => {
            toggleAddAlertModal();
            formik.resetForm();
            resetStates();
          }}
          disabled={isLoading}
        >
          {t("Cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
