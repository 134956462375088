import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import Select from "react-select";

const BreadCrumb = ({
  title,
  pageTitle,
  handleCenter,
  locationTitle,
  toggleMapType,
  selectedMulti,
  setselectedMulti,
  DangerClassificationType,
  setDangerClassificationType,
}) => {
  const [SingleOptions, setSingleOptions] = useState([
    { value: "Online", label: "Online" },
    { value: "Offline", label: "Offline" },
    { value: "Danger", label: "Danger" },
    { value: "semi-Online", label: "semi-Online" },
  ]);

  const classificationTypeOptions = [
    { value: "30%", label: "30% rules" },
    { value: "FDI", label: "FDI FORMULA" },
  ];

  useEffect(() => {
    // Check the selected classification type
    const selectedClassificationType = DangerClassificationType?.value || "";

    // Update SingleOptions based on the selected classification type
    if (selectedClassificationType === "30%") {
      setSingleOptions([
        { value: "good", label: "Good" },

        { value: "danger", label: "Danger" },
      ]);
      setselectedMulti([
        { value: "good", label: "Good" },
        { value: "danger", label: "Danger" },
      ]);
    } else {
      // Update with a different set of options when classification type is not "30%"
      setSingleOptions([
        { value: "low", label: "low" },
        { value: "moderate", label: "moderate" },
        { value: "high", label: "high" },
        { value: "very high", label: "very high" },
        { value: "extreme", label: "extreme" },
        { value: "catastrophic", label: "catastrophic" },

        // Add more options as needed
      ]);
      setselectedMulti([
        { value: "low", label: "low" },
        { value: "moderate", label: "moderate" },
        { value: "high", label: "high" },
        { value: "very high", label: "very high" },
        { value: "extreme", label: "extreme" },
        { value: "catastrophic", label: "catastrophic" },

        // Add more options as needed
      ]);
    }
  }, [DangerClassificationType]);

  function handleMulti(selectedMulti) {
    // update the store of the selected option
    setselectedMulti(selectedMulti);
  }

  return (
    <React.Fragment>
      <Row className="align-items-center mb-3 ">
        {/* Title */}
        <Col xs={12} lg={4}>
          <h4 className="mb-sm-0  ">{t(title)}</h4>
        </Col>

        {/* Status Selector */}
        <Col style={{ zIndex: "1200" }} xs={12} lg={4} className="my-3 my-xs-0">
          <h4>
            <strong>Status:</strong>
          </h4>
          <Select
            value={selectedMulti}
            isMulti={true}
            onChange={(e) => {
              handleMulti(e);
            }}
            options={SingleOptions}
          />
        </Col>

        {/* Formula Selector */}
        <Col style={{ zIndex: "1200" }} xs={12} lg={4}>
          <h4>
            <strong>FORMULA:</strong>
          </h4>
          <Select
            value={DangerClassificationType}
            isMulti={false}
            onChange={(e) => {
              setDangerClassificationType(e);
            }}
            options={classificationTypeOptions}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
