/* global google */

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { RenderFormikInput } from "../../Components/Common/Forms/FormikInputHelper";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useDevicesStore } from "../../stores/Devices";
import { useWilayasStore } from "../../stores/Wilayas";

export const EditDevice = ({ rowData, showModal, toggleModal }) => {
  const [selectedType, setSelectedType] = useState(rowData.type);
  const [selectedWilaya, setSelectedWilaya] = useState(rowData?.wilaya?._id);
  const [wilayaList, setWilayaList] = useState([]);
  const { getWilayas, wilayas } = useWilayasStore((state) => state);

  useEffect(() => {}, []);

  useEffect(() => {
    getWilayas && getWilayas();
  }, []);

  useEffect(() => {
    const neededWilayaAttributes = wilayas.map((wilaya) => {
      return {
        label: `${wilaya.wilayaId}-${wilaya.name}`,
        value: wilaya._id,
      };
    });
    //
    setWilayaList(neededWilayaAttributes);
  }, [wilayas]);

  const formik = useFormik({
    initialValues: {
      label: rowData.label,
      type: rowData.type,
      version: rowData.version,
      wilaya: rowData?.wilaya?._id,
      daira: rowData.daira,
      commune: rowData.commune,
      status: rowData.status,
      lat: rowData.location?.coordinates[0],
      lng: rowData.location?.coordinates[1],
    },
    validationSchema: Yup.object({
      label: Yup.string().required(t("Required")),
      type: Yup.string().required(t("Required")),
      version: Yup.string().required(t("Required")),
      wilaya: Yup.string().required(t("Required")),
      daira: Yup.string().required(t("Required")),
      commune: Yup.string().required(t("Required")),
      status: Yup.string(),
      // region: Yup.string().required(t("Required")),
      lat: Yup.string().required(t("Required")),
      lng: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      //

      const body = {
        ...values,
        location: [+values.lat, +values.lng],
      };
      delete body.lat;
      delete body.lng;

      const result = await editDevice(rowData._id, body);
      if (result) {
        formik.resetForm();
        toggleModal();
        resetStates();
      }
    },
  });

  const fieldsToRender = [
    { fieldName: "label", label: "Label", fullWidth: false },
    {
      fieldName: "type",
      label: "Type",
      placeholder: t("Select"),
      type: "select",
      options: [
        { value: "Edge camera", label: "Edge camera" },
        { value: "windTemp", label: "windTemp" },
      ],
      // value: selectedType,
      onChange: (option) => {
        setSelectedType(option);
      },
      preSelectedValue: selectedType,
    },

    { fieldName: "version", label: "Version", fullWidth: false },
    {
      fieldName: "wilaya",
      label: "Wilaya",
      placeholder: t("Select"),
      type: "select",
      options: wilayaList,
      value: selectedWilaya,
      onChange: (option) => {
        setSelectedWilaya(option);
      },
    },
    { fieldName: "daira", label: "Daira", fullWidth: true },
    { fieldName: "commune", label: "Commune", fullWidth: true },
    { fieldName: "status", label: "Status", fullWidth: true },
    { fieldName: "lat", label: "Latitude", fullWidth: false },
    {
      fieldName: "lng",
      label: "Longitude",
      fullWidth: false,
      inputProps: {
        onBlur: () => {
          const lat = formik.values.lat;
          const lng = formik.values.lng;

          if (lat && lng) {
            setMarkerPosition({
              lat: +lat,
              lng: +lng,
            });
          }
        },
      },
    },
  ];
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: "YOUR_API_KEY",
  });
  const center = {
    lat: rowData.location?.coordinates[0],
    lng: rowData.location?.coordinates[1],
  };
  const [markerPosition, setMarkerPosition] = useState(center);
  const { editDevice, isLoading } = useDevicesStore((state) => state);
  const resetStates = () => {
    setSelectedType(null);
    setSelectedWilaya(null);
    setMarkerPosition(center);
  };
  return (
    <Modal isOpen={showModal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{t("Edit Device")}</ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} className="d-flex flex-wrap">
          {fieldsToRender.map((field) => (
            <div
              key={field.fieldName}
              className={`flex-fill mb-2 ${field.fullWidth ? "w-100" : ""}`}
              style={field.fullWidth ? {} : { width: "48%", marginRight: 2 }}
            >
              {RenderFormikInput(formik, field)}
            </div>
          ))}

          <Label htmlFor={"location"} className="w-100 form-label mt-1">
            {t("Location")}
          </Label>

          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "200px",
            }}
            center={center}
            zoom={10}
            // onLoad={onLoad}
            // onUnmount={onUnmount}
          >
            <Marker
              position={markerPosition}
              // onClick={() => {
              //   handleMarkerClick(markerData);
              // }}
              draggable={true}
              onDragEnd={(info) => {
                const lat = info.latLng.lat();
                const lng = info.latLng.lng();
                setMarkerPosition({
                  lat: lat,
                  lng: lng,
                });
                formik.setFieldValue("lat", lat);
                formik.setFieldValue("lng", lng);
              }}
            />
          </GoogleMap>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          onClick={formik.handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size={"sm"} /> : <span>{t("Edit")}</span>}
        </Button>
        <Button
          color="danger"
          onClick={() => {
            toggleModal();
            formik.resetForm();
          }}
          disabled={isLoading}
        >
          {t("Cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
