import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ShowContact = ({ contact, showContactModule, handleMarkerClick }) => {
  return (
    <Modal isOpen={showContactModule} toggle={handleMarkerClick}>
      <ModalHeader>
        <div>
          <h4>VIGIE CONTACT</h4>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="popup bg-blue-a200">
          <p>
            <strong>Number: </strong>
            {contact}
          </p>

          <button onClick={() => handleMarkerClick(null)}>Close</button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShowContact;
