import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";

export const useUpdatedStore = create((set, get) => ({
  lastUpdate: new Date().getTime(),

  reload: () => {
    // set({ filters: filterNonFalseValues(filters) });

    set({ lastUpdate: new Date().getTime() });
  },
}));
