/* eslint-disable no-debugger */
/* global google */

import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, InfoWindow, useLoadScript } from "@react-google-maps/api";
import "./extra.scss";
import FlameSVG from "../../assets/imgs/flame.svg";
import greenState from "../../assets/imgs/states/map_icon_green.png";
import redState from "../../assets/imgs/states/map_icon_red.png";
import grayState from "../../assets/imgs/states/map_icon_gray.png";
import orangeState from "../../assets/imgs/states/map_icon_orange.png";
import { MapInfoWindow } from "./MapInfoWindow";
import BreadCrumb from "./BreadCrumb";
import { useAlertsStore } from "../../stores/Alerts";

import { useMonitoringStatusStore } from "../../stores/MonitoringStatus";
import { GOOGLE_MAPS_REFRESH_RATE } from "../../common/thresholds";
import { SiteInfoWindow } from "./SiteInfoWindow";
import { Marker, MapContainer, TileLayer, Popup } from "react-leaflet";
import { Icon, divIcon } from "leaflet";
import {
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useSelector } from "react-redux";
export const MonitoringMapview = () => {
  const title = "Map View";
  const mapRef = useRef(null);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [openMarkerId, setOpenMarkerId] = useState(null);
  const [DangerClassificationType, setDangerClassificationType] = useState({
    value: "30%",
    label: "30% rules",
  });
  const [mapMode, setMapMode] = useState("normal");
  const [layer, setLayer] = useState(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  );
  const { layoutModeType } = useSelector((state) => ({
    layoutModeType: state.Layout.layoutModeType,
  }));
  const [isOpen, setIsOpen] = useState(false);
  const [isCenter, setIsCenter] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [alertWindowData, setAlertWindowData] = useState();
  const [markers, setMarkers] = useState([]);
  // const [DeviceMarkers, setDeviceMarkers] = useState([]);

  // const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [siteMarkers, setSiteMarkers] = useState([]);
  const [selectedSiteMarkers, setSelectedSiteMarkers] = useState([]);
  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    googleMapsApiKey: "AIzaSyBE8gQ_BC1LxAAxkV4pHvLx2F1d6t2QLmQ",
  });

  const { getAlerts, isLoading, mapAlerts } = useAlertsStore((state) => state);

  const {
    sitesData,
    SiteStatusIsLoading,
    getLatestPerSite,
    fetchLatestPerSite,
  } = useMonitoringStatusStore((state) => state);

  const handleMarkerClick = (markerData) => {
    setAlertWindowData(markerData);
    setOpenMarkerId(markerData._id);
    setIsOpen(true);
    setIsCenter(true);

    setTimeout(() => {
      setIsCenter(false);
    }, GOOGLE_MAPS_REFRESH_RATE - 2000);

    // So after this the center is not Algeria
  };
  useEffect(() => {
    if (mapMode === "normal") {
      setLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    } else {
      setLayer("https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}");
    }
  }, [mapMode]);
  useEffect(() => {
    if (!mapAlerts?.length) return;
    setMarkers(mapAlerts);
  }, [mapAlerts]);

  const handleSiteMarkerClick = (markerData) => {
    setInfoWindowData(markerData);
    setOpenMarkerId(markerData.siteInfo._id);

    setIsOpen(true);
    setIsCenter(true);

    // setTimeout(() => {
    //   setIsCenter(false);
    // }, GOOGLE_MAPS_REFRESH_RATE - 2000);
  };

  useEffect(() => {
    getAlerts && getAlerts();
    fetchLatestPerSite && fetchLatestPerSite();
    // getLatestPerSite && getLatestPerSite(DangerClassificationType.value);

    // getLatestPerDevice && getLatestPerDevice();
  }, []);

  // useEffect(() => {
  // }, []);

  useEffect(() => {
    //sitesData includes sites with both wind and temperature data & sites with no data

    if (!sitesData.length) return;
    const filteredSites = sitesData.filter(
      (site) => site.tempData && site.windData
    );
    //we only keep sites that have data to show atm

    setSiteMarkers(filteredSites);
  }, [sitesData]);

  //************responsible for linking site's markers to the filter selector************ */

  useEffect(() => {
    let selectedmarks = siteMarkers;

    selectedmarks = selectedmarks.filter((site) => {
      for (const selected of selectedMulti) {
        if (DangerClassificationType.value === "FDI") {
          if (
            selected.value.toLowerCase() ===
            site.siteInfo.statusFDI.toLowerCase()
          ) {
            return true;
          }
        } else {
          if (
            selected.value.toLowerCase() ===
            site.siteInfo.status30.toLowerCase()
          ) {
            return true;
          }
        }
      }

      return false;
    });

    setSelectedSiteMarkers(selectedmarks);
  }, [selectedMulti, siteMarkers, DangerClassificationType]);

  useEffect(() => {
    if (selectedSiteMarkers.length === 0 || !mapRef.current) return;
    const allMarkers = [
      ...(selectedSiteMarkers.length > 0 ? selectedSiteMarkers : []),
      ...(markers.length > 0 ? markers : []),
    ];

    const bounds = allMarkers.map((markerData) => {
      if (Object.keys(markerData).length !== 0)
        return [
          markerData?.siteInfo?.location?.coordinates[0] ||
            markerData?.location?.coordinates[0],
          markerData?.siteInfo?.location?.coordinates[1] ||
            markerData?.location?.coordinates[1],
        ];
    });

    mapRef.current.fitBounds(bounds);
  }, [selectedSiteMarkers]);
  const defaultProps = {
    center: {
      lat: 30.0444,
      lng: 31.2357,
    },
    algeria: {
      lat: 36.37377988878743,
      lng: 3.8951729813480367,
    },
    zoom: 9,
  };
  const [mapType, setMapType] = useState("satellite");

  const toggleMapType = () => {
    if (mapType === "satellite") {
      setMapType("roadmap");
    } else {
      setMapType("satellite");
    }
  };
  //set the marker's Icons depending on the classification
  const chooseSiteClassName = (classification) => {
    const selectedClassificationType = DangerClassificationType?.value || "";

    if (selectedClassificationType === "30%") {
      // Use the siteClassification30 property when the classification type is "30%"
      if (classification.toLowerCase() === "danger") {
        return "danger-icon";
      } else if (classification.toLowerCase() === "good") {
        return "online-icon";
      } else if (classification.toLowerCase() === "offline") {
        return "offline-icon";
      } else if (classification.toLowerCase() === "semi-online") {
        return "semi-online-icon";
      }
    } else {
      // Use the siteClassificationFDI property when the classification type is not "30%"
      if (classification === "low") {
        return "low-icon";
      } else if (classification === "moderate") {
        return "moderate-icon";
      } else if (classification === "high") {
        return "high-icon";
      } else if (classification === "very high") {
        return "very-high-icon";
      } else if (classification === "extreme") {
        return "extreme-icon";
      } else if (classification === "catastrophic") {
        return "catastrophic-icon";
      }
      // Add more conditions for other classifications as needed
    }
  };

  if (isLoading || SiteStatusIsLoading) {
    return <h1>Loading...</h1>;
  }
  return (
    <React.Fragment>
      <BreadCrumb
        title={title}
        setselectedMulti={setselectedMulti}
        selectedMulti={selectedMulti}
        DangerClassificationType={DangerClassificationType}
        setDangerClassificationType={setDangerClassificationType}
        toggleMapType={toggleMapType}
      />

      <div
        style={{ position: "relative", height: "70vh", width: "100%" }}
        className={`${
          layoutModeType === "dark" && mapMode === "normal"
            ? "leaflet-darkmode"
            : ""
        } map-main relative`}
      >
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            zIndex: "1000",
          }}
          className=""
        >
          <ButtonGroup>
            <UncontrolledDropdown>
              <DropdownToggle tag="button" className="btn btn-light">
                Map Mode <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setMapMode("normal")}>
                  Normal
                </DropdownItem>
                <DropdownItem onClick={() => setMapMode("satellite")}>
                  Satellite
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ButtonGroup>
        </div>
        <MapContainer
          className="leaflet-bounds"
          ref={mapRef}
          style={{ height: "100%" }}
          center={
            isCenter
              ? [
                  infoWindowData?.siteInfo.location?.coordinates[0],
                  infoWindowData?.siteInfo.location?.coordinates[1],
                ]
              : [36.37377988878743, 3.8951729813480367]
          }
          zoom={defaultProps.zoom}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url={layer}
          />

          {selectedSiteMarkers.map((markerData) => (
            <Marker
              key={markerData.siteInfo._id}
              icon={
                new divIcon({
                  iconSize: [20, 20],
                  iconAnchor: [15, 15],
                  className: chooseSiteClassName(
                    DangerClassificationType.value === "30%"
                      ? markerData.siteInfo.status30
                      : markerData.siteInfo.statusFDI
                  ),
                })
              }
              // icon={
              //   new Icon({
              //     iconUrl: chooseSiteIconUrl(
              //       DangerClassificationType.value === "30%"
              //         ? markerData.siteClassification30
              //         : markerData.siteClassificationFDI
              //     ),
              //     iconSize: [18, 18],
              //   })
              // }
              position={[
                markerData.siteInfo.location?.coordinates[0],
                markerData.siteInfo.location?.coordinates[1],
              ]}
              eventHandlers={{
                click: (e) => {
                  handleSiteMarkerClick(markerData);
                },
              }}
            >
              <Popup>
                {infoWindowData && <SiteInfoWindow info={infoWindowData} />}
              </Popup>
            </Marker>
          ))}
          {markers
            .filter((marker) => marker.location)
            .map((markerData) => (
              <Marker
                key={markerData._id}
                icon={
                  new Icon({
                    iconUrl: FlameSVG, //change depending on the type of marker
                    iconSize: [25, 25],

                    // fillColor: "blue" // changes depending on the color of the marker
                  })
                }
                position={[
                  markerData.location?.coordinates[0],
                  markerData.location?.coordinates[1],
                ]}
                eventHandlers={{
                  click: (e) => {
                    handleMarkerClick(markerData);
                  },
                }}
              >
                <Popup>
                  {alertWindowData && <MapInfoWindow info={markerData} />}
                </Popup>
              </Marker>
            ))}
        </MapContainer>

        {/* <GoogleMap
          mapContainerClassName="w-100 h-100"
          center={
            isCenter && {
              lat: infoWindowData?.siteInfo.location?.coordinates[0],
              lng: infoWindowData?.siteInfo.location?.coordinates[1],
            }
          }
          zoom={defaultProps.zoom}
          // onLoad={fitBoundsHandler}
          // onClick={() => setIsOpen(false)}
          ref={mapRef}
          scaledSize={new google.maps.Size(0, 0.1)}
          mapTypeId={mapType}
          onLoad={(map) => {
            mapRef.current = map; // Set the map instance to mapRef
          }}
          // extraMapTypes={[]}
          // options={{
          //   // disableDefaultUI: true,
          //   mapTypeControl: true,
          //   mapTypeId: google.maps.MapTypeId.ROADMAP,
          //   mapTypeControlOptions: {
          //     style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          //     position: google.maps.ControlPosition.TOP_LEFT,
          //     mapTypeIds: [
          //       google.maps.MapTypeId.ROADMAP,
          //       google.maps.MapTypeId.SATELLITE,
          //       google.maps.MapTypeId.HYBRID,
          //     ],
          //   },
          // }}
        >

          {/* {selectedMarkers.map((markerData) => (
            <Marker
              key={markerData.tempData.deviceId._id}
              icon={{
                url: chooseIconUrl(markerData.classification),
                scaledSize: new google.maps.Size(20, 20),
                origin: new google.maps.Point(0, 0),
              }}
              position={{
                lat: markerData.tempData.deviceId.location?.coordinates[0],
                lng: markerData.tempData.deviceId.location?.coordinates[1],
              }}
              onClick={() => {
                handleMarkerClick(markerData);
              }}>
              {openMarkerId === markerData.tempData.deviceId._id && (
                <InfoWindow
                  onCloseClick={() => {
                    setOpenMarkerId(null);
                  }}>
                  <StatusInfoWindow info={markerData} />
                </InfoWindow>
              )}
            </Marker>
          ))} 
          {selectedSiteMarkers.map((markerData) => (
            <Marker
              key={markerData.siteInfo._id}
              icon={{
                url: chooseSiteIconUrl(markerData.siteClassification),
                scaledSize: new google.maps.Size(18, 18),
                origin: new google.maps.Point(0, 0),
              }}
              position={{
                lat: markerData.siteInfo.location?.coordinates[0],
                lng: markerData.siteInfo.location?.coordinates[1],
              }}
              onClick={() => {
                handleSiteMarkerClick(markerData);
              }}>
              {openMarkerId === markerData.siteInfo._id && (
                <InfoWindow
                  onCloseClick={() => {
                    setOpenMarkerId(null);
                  }}>
                  <SiteInfoWindow info={markerData} />
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap> */}
      </div>
    </React.Fragment>
  );
};
