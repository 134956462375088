import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";
import moment from "moment";
async function fetchSiteIdFromDeviceId(deviceId) {
  try {
    const device = await axiosHelper.get(`/system/device/get/${deviceId}`); // Assuming 'devId' is the field in your schema that represents the deviceId
    if (device) {
      return device.data.siteId; // Assuming 'siteId' is the field in your schema that represents the siteId
    } else {
      return null; // Return null if no device with the given deviceId is found
    }
  } catch (error) {
    console.error("Error fetching siteId from deviceId:", error);
    throw error;
  }
}
const fetchSitesData = async (bodyforPeriod) => {
  const responseTemp = await axiosHelper.post(
    "/system/siteHistory/get",
    bodyforPeriod
  );
  if (responseTemp.result) {
    return {
      data: responseTemp.data,
      alternatives: responseTemp.alternatives,
    };
  } else {
    throw new Error("Failed to fetch temperature data");
  }
};
export const useDashboardTwoStore = create((set, get) => ({
  TempPerPeriod: [],
  WindPerPeriod: [],
  datePerPeriod: [],
  sitesHistoryData: [],
  alternatives: [],
  datePerPeriodPerSite: [],
  updatedAt: new Date().getTime(),
  temperature: [],
  humudity: [],
  wind: [],
  isLoading: false,
  isError: false,
  error: undefined,
  filters: null,
  resultsCount: undefined,
  // Methods
  getPerPeriod: async (body) => {
    try {
      set({ isLoading: true });

      if (!body.interval) {
        throw new Error("invalid arguments");
      }

      const today = moment().format("YYYY-MM-DD");
      let tempData = null;
      // let windData = null;

      let bodyforPeriod = { interval: body.interval, date: today };

      // Create a function to fetch temperature data
      const fetchTemperatureData = async () => {
        const responseTemp = await axiosHelper.post(
          "/system/temp/period",
          bodyforPeriod
        );
        if (responseTemp.result) {
          return responseTemp.data;
        } else {
          throw new Error("Failed to fetch temperature data");
        }
      };

      // Create a function to fetch wind data
      const fetchWindData = async () => {
        const responseWind = await axiosHelper.post(
          "/system/wind/period",
          bodyforPeriod
        );
        if (responseWind.result) {
          return responseWind.data;
        } else {
          throw new Error("Failed to fetch wind data");
        }
      };

      // Use Promise.all to fetch both temperature and wind data concurrently
      const [temperatureData, windData] = await Promise.all([
        fetchTemperatureData(),
        fetchWindData(),
      ]);

      //temp and wind data contain data attribute that lists all the historical data on that hour equipped with the device Id
      //NOW we need to list that data in a way where its identified by the deviceID, and then link it to the site and create an object like this one : {siteId, temp, humidity,speed and direction }
      // Create an array of objects containing temperature, humidity, speed, and direction
      const combinedData = temperatureData.map((tempObj, index) => ({
        temperature: tempObj?.temperature,
        humidity: tempObj?.humidity,
        speed: windData[index]?.speed,
        direction: windData[index]?.direction,
      }));
      set({
        datePerPeriod: combinedData,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      set({ isError: true, error });
    } finally {
      set({ isLoading: false });
    }
  },

  getPerPeriodPerSite: async (body) => {
    try {
      set({ isLoading: true });

      if (!body.interval) {
        throw new Error("invalid arguments");
      }

      const today = moment();
      // Subtract one day to get yesterday's date
      const yesterday = today.subtract(1, "day");

      // Format the date as "YYYY-MM-DD"
      const yesterdayFormatted = yesterday.format("YYYY-MM-DD");
      let bodyforPeriod = { interval: body.interval, date: yesterdayFormatted };

      // Create a function to fetch temperature data
      const fetchTemperatureData = async () => {
        const responseTemp = await axiosHelper.post(
          "/system/temp/period",
          bodyforPeriod
        );
        if (responseTemp.result) {
          return responseTemp.data;
        } else {
          throw new Error("Failed to fetch temperature data");
        }
      };

      // Create a function to fetch wind data
      const fetchWindData = async () => {
        const responseWind = await axiosHelper.post(
          "/system/wind/period",
          bodyforPeriod
        );
        if (responseWind.result) {
          return responseWind.data;
        } else {
          throw new Error("Failed to fetch wind data");
        }
      };

      // Fetch both temperature and wind data concurrently
      const [temperatureData, windData] = await Promise.all([
        fetchTemperatureData(),
        fetchWindData(),
      ]);

      // Create an object to store the site data
      const siteData = {};

      // Iterate through temperature data
      for (const tempEntry of temperatureData) {
        for (const tempData of tempEntry.data) {
          const deviceId = tempData.deviceId;
          const siteId = await fetchSiteIdFromDeviceId(deviceId);

          if (siteId) {
            if (!siteData[siteId]) {
              siteData[siteId] = {
                siteId,
                temperatureData: [],
                windData: [],
              };
            }

            siteData[siteId].temperatureData.push({
              temp: parseFloat(tempData.temperature).toFixed(0),
              humidity: parseFloat(tempData.humidity).toFixed(0),
            });
          }
        }
      }

      // Iterate through wind data
      for (const windEntry of windData) {
        for (const windData of windEntry.data) {
          const deviceId = windData.deviceId;
          const siteId = await fetchSiteIdFromDeviceId(deviceId);

          if (siteId) {
            if (!siteData[siteId]) {
              siteData[siteId] = {
                siteId,
                temperatureData: [],
                windData: [],
              };
            }

            siteData[siteId].windData.push({
              speed: parseFloat(windData.speed).toFixed(0),
              direction: windData.direction,
            });
          }
        }
      }

      // Convert the grouped data back into an array
      const siteDataArray = Object.values(siteData);

      set({
        datePerPeriodPerSite: siteDataArray,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      set({ isError: true, error });
    } finally {
      set({ isLoading: false });
    }
  },

  siteHistoryForLast: async (body) => {
    try {
      set({ isLoading: true });

      if (!body.interval) {
        throw new Error("invalid arguments");
      }

      const dateTo = moment();
      // Subtract one day to get yesterday's date
      const dateFrom = dateTo
        .clone()
        .subtract(parseInt(body.interval), "minute");

      let bodyforPeriod = { dateFrom: dateFrom, dateTo: dateTo };

      // Create a function to fetch site dATA

      // Fetch both temperature and wind data concurrently
      const [sitesHistory] = await Promise.all([fetchSitesData(bodyforPeriod)]);

      const sitesHistoryData = sitesHistory.data;
      const alternatives = sitesHistory.alternatives;
      set({
        sitesHistoryData: sitesHistoryData,
        alternatives: alternatives,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      set({ isError: true, error });
    } finally {
      set({ isLoading: false });
    }
  },

  getTemperature: async (body) => {
    // set({ filters: filterNonFalseValues(filters) });
    // console.log(filterNonFalseValues(filters))
    // const body = {
    //   search: filterNonFalseValues(filters)?.search,
    //   // sortDirection: "desc",
    //   // sortField: "createdAt",
    //   // dateFrom: "",
    //   // dateTo: "",
    //   page: filters?.page || 1,
    //   limit: filters?.perPage || 10,
    // };

    try {
      set({ isLoading: true });
      let response = await axiosHelper.post("/system/avgtemp/dates", body);

      if (!response.result) {
        return;
      }

      set({
        temperature: response.data,
        resultsCount: response?.count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  getWind: async (body) => {
    try {
      set({ isLoading: true });
      let response = await axiosHelper.post("/system/avgwind/dates", body);

      if (!response.result) {
        return;
      }

      set({
        wind: response.data,
        resultsCount: response?.count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  updateTime: () => {
    set({ updatedAt: new Date().getTime() });
  },
}));
