import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useWilayasStore } from "../../../stores/Wilayas";
import { useSelector } from "react-redux";
import dgf from "../../../assets/images/banner.png";
import moment from "moment";
import Loader from "../../../Components/Common/Loader";

const legendItemStyle = {
  display: "flex",
  alignItems: "center",
  padding: "7px",
};

const TimeLegend = ({ updatedAt }) => {
  return (
    <div className="d-flex flex-row gap-2 p-2 align-items-center justify-content-center rounded-pill bg-black text-white border border-2 border-white">
      <p className="m-0">Last Update:</p>
      <p className="m-0">{moment(updatedAt).format("YYYY-MM-DD hh:mm:ss")}</p>
    </div>
  );
};

const LegendFDI = () => {
  return (
    <div className="d-flex flex-row align-items-center justify-content-center rounded-pill bg-success text-white border border-2 border-white">
      <div
        style={{ ...legendItemStyle, background: "rgba(81, 179, 55, 0.8)" }}
        className="rounded-end rounded-pill"
      >
        <div>Low</div>
      </div>
      <div style={{ ...legendItemStyle, background: "rgba(235, 236, 3, 0.8)" }}>
        <div>Moderate</div>
      </div>
      <div style={{ ...legendItemStyle, background: "rgba(238, 152, 58, 1)" }}>
        <div>High</div>
      </div>
      <div style={{ ...legendItemStyle, background: "rgba(225, 64, 47, 1)" }}>
        <div>Severe</div>
      </div>
      <div style={{ ...legendItemStyle, background: "rgba(172, 36, 27, 0.8)" }}>
        <div>Extreme</div>
      </div>
      <div
        style={{ ...legendItemStyle, background: "rgba(143, 43, 123, 0.8)" }}
        className="rounded-start rounded-pill"
      >
        <div>Catastrophic</div>
      </div>
    </div>
  );
};

const Dashboard4 = () => {
  const [geojsonData, setGeojsonData] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(true);
  const { cities, getCities, updatedAt, updateTime } = useWilayasStore(
    (state) => state
  );
  const { layoutModeType } = useSelector((state) => ({
    layoutModeType: state.Layout.layoutModeType,
  }));

  useEffect(() => {
    getCities && getCities();
    const intervalId = setInterval(() => {
      getCities && getCities();
      updateTime();
    }, 300000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (cities.length > 0) {
      const template = {
        type: "FeatureCollection",
        name: "gadm41_DZA_2",
        crs: {
          type: "name",
          properties: {
            name: "urn:ogc:def:crs:OGC:1.3:CRS84",
          },
        },
        features: cities,
      };

      setGeojsonData(template);
    }
  }, [cities]);

  const styling = (feature) => {
    const state = feature.properties.state;
    let fillColor, borderColor, borderWeight, opacity;

    switch (state) {
      case "catastrophic":
        fillColor = "#8f2b7b";
        borderColor = "#8f2b7b";
        borderWeight = 0.8;
        opacity = 0.8;
        break;
      case "extreme":
        fillColor = "#ac241b";
        borderColor = "#ac241b";
        borderWeight = 0.7;
        opacity = 0.7;
        break;
      case "severe":
        fillColor = "#e1402f";
        borderColor = "#e1402f";
        borderWeight = 0.6;
        opacity = 0.7;
        break;
      case "high":
        fillColor = "#ee983a";
        borderColor = "#ee983a";
        borderWeight = 0.5;
        opacity = 0.7;
        break;
      case "moderate":
        fillColor = "#ebec03";
        borderColor = "#ebec03";
        borderWeight = 0.4;
        opacity = 0.4;
        break;
      case "low":
        fillColor = "#51b337";
        borderColor = "#51b337";
        borderWeight = 0.3;
        opacity = 0.4;
        break;
      default:
        fillColor = "#808080";
        borderColor = "transparent";
        borderWeight = 0;
    }

    return {
      fillColor: fillColor,
      weight: borderWeight,
      color: borderColor,
      fillOpacity: opacity,
    };
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <>
      <small className="text-muted">
        Last Update: {moment(updatedAt).format("YYYY-MM-DD hh:mm:ss")}
      </small>

      <div
        style={{
          background: "darkgray",
          height: isFullscreen ? "100vh" : "80vh",
          width: "100%",
          position: isFullscreen ? "fixed" : "relative",
          top: isFullscreen ? 0 : "auto",
          left: isFullscreen ? 0 : "auto",
          zIndex: isFullscreen ? 1005 : "auto",
        }}
        className={`${
          layoutModeType === "dark" ? "leaflet-darkmode" : ""
        } map-main relative overflow-hidden`}
      >
        {cities.length === 0 ? (
          <div
            style={{ height: "70vh" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loader />
          </div>
        ) : (
          <>
            <div
              style={{
                position: "absolute",
                zIndex: 900,
                bottom: "0px",
                width: "100%",
                padding: "16px 10px",
                background:
                  "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))",
              }}
              className="shadow-lg d-flex flex-row justify-content-between align-items-center"
            >
              <div className="d-flex align-items-center gap-3 justify-content-center">
                <img src={dgf} height={40} alt="" />
              </div>
              <TimeLegend updatedAt={updatedAt} />
              <LegendFDI />
            </div>

            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1000,
                backgroundColor: "white",
                border: "none",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={toggleFullscreen}
              className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            >
              <i
                className={
                  isFullscreen
                    ? "bx bx-fullscreen fs-22"
                    : "bx bx-exit-fullscreen fs-22"
                }
              ></i>
            </button>

            <MapContainer
              center={[34.71499469967631, 3.2356691995749256]}
              zoom={7}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {geojsonData &&
                geojsonData?.features?.map((g, index) => (
                  <GeoJSON key={index} data={g} style={styling}></GeoJSON>
                ))}
            </MapContainer>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard4;
