import React from "react";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
const DifferentColor = ({
  warningData,
  offlineData,
  onlineData,
  dataColors,
}) => {
  var chartTimelineColors = getChartColorsArray(dataColors);
  const series = [
    {
      data: [
        {
          x: "Online",
          y: onlineData,
          fillColor: chartTimelineColors[2],
        },
        {
          x: "Offline",
          y: offlineData,
          fillColor: chartTimelineColors[1],
        },
        {
          x: "Warning",
          y: warningData,
          fillColor: chartTimelineColors[3],
        },
      ],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "rangeBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        dataLabels: {
          hideOverflowingLabels: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        var label = opts.w.globals.labels[opts.dataPointIndex];
        var a = moment(val[0]);
        var b = moment(val[1]);
        var diffSeconds = b.diff(a, "seconds");
        var diffMinutes = Math.floor(diffSeconds / 60);
        var diffHours = Math.floor(diffMinutes / 60);
        var diffDays = Math.floor(diffHours / 24);

        if (diffDays > 0) {
          return label + ": " + diffDays + (diffDays > 1 ? " days" : " day");
        } else if (diffHours > 0) {
          return (
            label + ": " + diffHours + (diffHours > 1 ? " hours" : " hour")
          );
        } else if (diffMinutes > 0) {
          return (
            label +
            ": " +
            diffMinutes +
            (diffMinutes > 1 ? " minutes" : " minute")
          );
        } else if (diffSeconds > 0) {
          return (
            label +
            ": " +
            diffSeconds +
            (diffSeconds > 1 ? " seconds" : " second")
          );
        } else {
          return "";
        }
      },
    },

    xaxis: {
      type: "datetime",
    },
    yaxis: {
      show: true,
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        className="apex-charts"
        options={options}
        series={series}
        type="rangeBar"
        height={350}
      />
    </React.Fragment>
  );
};
export default DifferentColor;
