import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";
import { frequent } from "../Components/Common/frequent";
import { isExpired } from "../Components/Common/isExpired";
import { max } from "../Components/Common/max";

export const useMonitoringStatusStore = create((set, get) => ({
  devicesData: [],
  sitesData: [],
  wind: null,
  StatusIsLoading: false,
  SiteStatusIsLoading: false,
  isError: false,
  error: undefined,
  filters: null,
  resultsCount: undefined,
  fetchLatestPerSite: async () => {
    let monitoredSites = null;
    set({ SiteStatusIsLoading: true });
    try {
      const response = await axiosHelper.get("/system/site/monitor");
      monitoredSites = response;
    } catch (err) {
      console.log(err);
      set({ SiteStatusIsLoading: false });
    }
    set({
      sitesData: monitoredSites,
      SiteStatusIsLoading: false,
    });
  },
  // Methods
  //get the latest temperature from the historical Data & the device responsible for that data to see its state and its coordinates
  //upon getting the data return all the data along with status, green, grey or red
  getLatestPerSite: async (classification_type) => {
    const temperatureThreshold = 30;
    const humidityThreshold = 30;
    const windSpeedThreshold = 15;
    const FDIthreshold = 1000;
    const classifiedData = [];

    try {
      set({ SiteStatusIsLoading: true });
      const sites = await axiosHelper.post("/system/site/get");
      //sites has classification built in FDI and 30%
      //
      //////****************************FETCHING DEVICES OF EACH SITE*********************************** */
      for (const site of sites.data) {
        const devices = await axiosHelper.post("/system/device/get", {
          siteId: site._id,
        });

        let siteClassification30 = "Online"; // Default classification for the site by the 30% rule
        let siteClassificationFDI = "Online"; // Default classification for the site by the FDI rule

        let allDevicesOffline = true; // Flag to check if all devices are offline
        let temperatureSum = 0; // Sum of temperature values for the site
        let temperatureCount = 0; // Count of temperature devices for the site
        let windSum = 0; // Sum of wind values for the site
        let windCount = 0; // Count of wind devices for the site
        let humiditySum = 0; // Sum of humidity values for the site
        let humidityCount = 0; // Count of humidity devices for the site
        let windDirectionSum = 0; // Sum of wind directions for the site
        let windDirectionCount = 0;
        const tempDevicesUsed = [];
        const windDevicesUsed = [];
        const siteTriggers = [];
        let latestDT = null;
        let offline_devices = 0;
        let expired = false;
        //**this is the direction necessary calculations  */
        const directions = [];

        const directionCount = {};

        for (const device of devices.data) {
          let trigger = [];
          //***************************DATA FETCHING BY TYPE OF DEVICE OF THE SITE************************** */
          let dataResponse = null;
          let optionalResponse = null;
          if (device.type === "temperature") {
            dataResponse = await axiosHelper.get(
              `/system/temp/latest/${device._id}`
            );
          } else if (device.type === "wind") {
            dataResponse = await axiosHelper.get(
              `/system/wind/latest/${device._id}`
            );
          } else if (device.type === "windTemp") {
            dataResponse = await axiosHelper.get(
              `/system/wind/latest/${device._id}`
            );
            optionalResponse = await axiosHelper.get(
              `/system/temp/latest/${device._id}`
            );
          }
          if (
            !dataResponse ||
            !dataResponse.result ||
            (!dataResponse && !optionalResponse)
          ) {
            continue; // Skip this device if there's no data
          }

          //******************************LATEST DETECTION TIME  *********************************/
          if (!latestDT) {
            latestDT = dataResponse.data?.detectionTime;
          } else {
            latestDT = max(dataResponse.data?.detectionTime, latestDT);
            if (optionalResponse) {
              latestDT = max(optionalResponse?.data?.detectionTime, latestDT);
            }
          }

          //******************************CHECK IF EXPIRED  *********************************/

          expired = isExpired(
            max(
              dataResponse?.data?.createdAt,
              optionalResponse?.data?.createdAt
            )
          );
          if (
            latestDT === "Invalid date" ||
            (!dataResponse?.data?.createdAt &&
              !optionalResponse?.data?.createdAt)
          ) {
            expired = true;
          }
          //**********************CLASSIFICATION & TRIGGERS*************************** */
          //**********************USE FDI OR 30% RULES ******************************* */

          //SETUP THE DEVICE CLASSIFICATION
          let deviceClassification = "Online"; // Default classification for the device

          if (
            dataResponse.data?.temperature > temperatureThreshold ||
            dataResponse.data?.humidity > humidityThreshold ||
            (device.type === "wind" &&
              dataResponse.data?.speed > windSpeedThreshold) ||
            optionalResponse?.data?.temperature > temperatureThreshold ||
            optionalResponse?.data?.humidity > humidityThreshold
          ) {
            deviceClassification = "Danger";
          }

          if (device.status === "offline") {
            // If the device didn't report any danger, consider it offline

            deviceClassification = "Offline";
            offline_devices++;
          } else {
            allDevicesOffline = false; // At least one device is not offline
          }

          // Count the average and keep track of devices used for each data type
          if (device.type === "temperature") {
            temperatureSum += dataResponse.data?.temperature || 0;
            humiditySum += dataResponse.data?.humidity || 0;
            if (dataResponse.data?.temperature) {
              temperatureCount++;
            }
            if (dataResponse.data?.humidity) {
              humidityCount++;
            }

            tempDevicesUsed.push({
              temperature: dataResponse?.data?.temperature,
              humidity: dataResponse?.data?.humidity,
              classification: deviceClassification,
              device: device,
              detectionTime: dataResponse.data?.detectionTime,
            });
          } else if (device.type === "wind") {
            //direction is a string that  needs to be calculated
            directions.push(dataResponse.data?.direction);
            // windDirectionSum += dataResponse.data?.direction || 0;
            // windDirectionCount++;
            windSum += dataResponse.data?.speed || 0;
            if (dataResponse.data?.speed) {
              windCount++;
            }

            windDevicesUsed.push({
              speed: dataResponse.data?.speed,
              direction: dataResponse.data?.direction,
              device: device,

              classification: deviceClassification,
              trigger,
              detectionTime: dataResponse.data?.detectionTime,
            });
          } else if (device.type === "windTemp") {
            temperatureSum += optionalResponse?.data?.temperature || 0;
            humiditySum += optionalResponse?.data?.humidity || 0;

            humidityCount = optionalResponse?.data?.humidity
              ? humidityCount + 1
              : humidityCount;
            temperatureCount = optionalResponse?.data?.temperature
              ? temperatureCount + 1
              : temperatureCount;
            //direction is a string that  needs to be calculated
            directions.push(dataResponse.data?.direction);

            // windDirectionSum += dataResponse.data?.direction || 0;
            // windDirectionCount++;

            windSum += dataResponse.data?.speed || 0;
            if (dataResponse.data?.speed) {
              windCount++;
            }

            tempDevicesUsed.push({
              temperature: optionalResponse?.data?.temperature,
              humidity: optionalResponse?.data?.humidity,
              device: device,
              classification: deviceClassification,
              trigger,
              detectionTime: optionalResponse?.data?.detectionTime,
            });

            windDevicesUsed.push({
              speed: dataResponse.data?.speed,
              direction: dataResponse.data?.direction,
              device: device,
              classification: deviceClassification,
              trigger,
              detectionTime: dataResponse.data?.detectionTime,
            });
          }
        }

        //**********************AVERAGE CALUCLATIONS FOR EACH PARAMS

        const common_direction = frequent(directions);

        const siteAverageTemperature = parseFloat(
          temperatureCount > 0 ? temperatureSum / temperatureCount : null
        ).toFixed(0);
        const siteAverageWindSpeed = parseFloat(
          windCount > 0 ? windSum / windCount : null
        ).toFixed(0);
        const siteAverageHumidity = parseFloat(
          humidityCount > 0 ? humiditySum / humidityCount : null
        ).toFixed(0);

        const siteAverageWindDirection =
          windDirectionCount > 0 ? windDirectionSum / windDirectionCount : null;

        //WHEN WE HAVE ALL THE AVERAGE METRICS PER SITE WE CAN APPLY THE FORMULAS

        //the 30% rule

        if (
          siteAverageHumidity <= humidityThreshold ||
          siteAverageWindSpeed >= windSpeedThreshold ||
          siteAverageTemperature >= temperatureThreshold
        ) {
          siteClassification30 = "Danger";
          if (siteAverageHumidity <= humidityThreshold) {
            siteTriggers.push("humidity");
          }
          if (siteAverageWindSpeed >= windSpeedThreshold) {
            siteTriggers.push("wind");
          }
          if (siteAverageTemperature >= temperatureThreshold) {
            siteTriggers.push("temperature");
          }
        }

        // site classification based on the FDI rule
        const siteAveragePrecipitation = await axiosHelper.post(
          `/system/precipitation/get`,
          { coordinates: site?.location?.coordinates }
        );
        const FDI =
          10 * siteAverageTemperature +
          10 * siteAveragePrecipitation +
          10 * (1 - siteAverageHumidity / 100) +
          10 * siteAverageWindSpeed +
          10 * siteAverageWindDirection;
        if (FDI >= FDIthreshold) {
          siteClassificationFDI = "Danger";
        }

        //checks if the site is currently offline
        //the FDI check and the 30% rule check are seperated
        if (
          siteClassificationFDI !== "Danger" &&
          classification_type === "FDI"
        ) {
          if (allDevicesOffline) {
            siteClassificationFDI = "Offline";
          } else if (offline_devices > 0) {
            siteClassificationFDI = "semi-Online";
          }
        }

        if (
          siteClassification30 !== "Danger" &&
          classification_type === "30%"
        ) {
          if (allDevicesOffline) {
            siteClassification30 = "Offline";
          } else if (offline_devices > 0) {
            siteClassification30 = "semi-Online";
            siteClassificationFDI = "semi-Online";
          }
        }

        //update the site status /********************************* */

        //handle the trigger
        classifiedData.push({
          siteInfo: site, // Store site information
          siteClassification30, // Store site classification
          siteClassificationFDI,
          triggers: siteTriggers, // Store triggers
          detectionTime: latestDT,
          expired: expired,
          tempData: {
            avg_temp: siteAverageTemperature,
            avg_humi: siteAverageHumidity,
            devices_used: tempDevicesUsed,
          },
          windData: {
            avg_speed: siteAverageWindSpeed,
            avg_direction: siteAverageWindDirection || common_direction,
            devices_used: windDevicesUsed,
          },
        });
      }

      set({
        sitesData: classifiedData,
        SiteStatusIsLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ SiteStatusIsLoading: false });
    }
  },

  getLatestPerDevice: async () => {
    // Define your thresholds for temperature and humidity
    const temperatureThreshold = 30; // Adjust this threshold as needed
    const humidityThreshold = 60; // Adjust this threshold as needed
    const windSpeedThreshold = 15;
    const classifiedData = [];

    try {
      set({ StatusIsLoading: true });
      const devices = await axiosHelper.post("/system/device/get");
      for (const device of devices.data) {
        let tempResponse = await axiosHelper.get(
          `/system/temp/latest/${device._id}`
        );

        //filter the tempResponse data using the body
        if (!tempResponse.result) {
          return;
        }

        let windResponse = await axiosHelper.get(
          `/system/wind/latest/${device._id}`
        );
        // Filter the response.data based on selected values
        if (!windResponse.result) {
          return;
        }

        //return the device+its latest data+ classification
        // Classify the device based on the criteria
        let classification = "Online";
        let trigger = [];
        if (
          tempResponse.data?.temperature > temperatureThreshold ||
          tempResponse.data?.humidity > humidityThreshold ||
          windResponse.data?.speed > windSpeedThreshold
        ) {
          classification = "Danger";
          if (tempResponse.data?.humidity > humidityThreshold) {
            trigger.push("humidity");
          }
          if (tempResponse.data?.temperature > temperatureThreshold) {
            trigger.push("temperature");
          }
          if (windResponse.data?.speed > windSpeedThreshold) {
            trigger.push("wind");
          }
        }

        if (device.status === "offline" && classification === "Online") {
          //if classification is not danger
          classification = "Offline";
        }
        // Add the device and its classification to the classifiedData array
        classifiedData.push({
          tempData: tempResponse.data,
          windData: windResponse.data,
          classification,
          trigger,
        });
      }
      set({
        devicesData: classifiedData,
        StatusIsLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ StatusIsLoading: false });
    }
  },
}));
