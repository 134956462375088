import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import styled from "styled-components";
import { capitalizeFirstCharacter } from "../../stores/helpers/Utlis";

const StyledInfoWindow = styled.div`
  width: 350px;
  background-color: white;
  color:black;
  // cursor: pointer;
  // height: 250px;
  // border-radius: 5px;
  // border: 1px solid black;
  // position: relative;
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     bottom: -40px;
  //     left: 0;
  //     border: 20px solid black;
  //     border-color: white transparent transparent transparent;
  //     border-radius: 5px;
  //   }
`;
const StyledInfoModal = styled.div`
  padding: 10px;
`;

export const MapInfoWindow = ({ info }) => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      <StyledInfoWindow
        onClick={() => {
          setShowModal(true);
        }}
      >
        <ModalHeader  className="p-2">
          <div>Device Id : {info.devId}</div>
          {info.status === "online" ? (
            <span className="text-success">Online</span>
          ) : (
            <span className="text-danger">Offline</span>
          )}
          <div>{info.statusDetails}</div>
        </ModalHeader>

        <ul className="d-flex flex-wrap gap-2">
          <li className="w-50">Type : {info.type}</li>
          <li className="w-50">Label : {info.label}</li>
          <li className="w-50">Version : {info.version}</li>

          <li className="w-50">
            Location :
            {`${capitalizeFirstCharacter(
              info.region
            )} , ${capitalizeFirstCharacter(info.wilaya)} `}
          </li>
          <li className="w-50">{` Coordinates: [ ${info.location?.coordinates[0]} , ${info.location?.coordinates[1]} ] `}</li>
        </ul>
      </StyledInfoWindow>
    </>
  );
};
