import React, { useState } from "react";
import { axiosHelper } from "../../stores/helpers";
import { convertToBase64 } from "../../Components/Common/convertToBase64";

const Index = () => {
  const [image, setImage] = useState();
  const upload = async () => {
    if (image) {
      let response = await axiosHelper.post("/system/alert/create", {
        image,
      });
    }
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setImage(base64);
  };
  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleFileUpload(e)}
      />
      <button onClick={upload}>upload</button>
    </div>
  );
};

export default Index;
