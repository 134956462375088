import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";
import moment from "moment";

export const useDashboardThreeStore = create((set, get) => ({
  regionForecasts: [],
  isLoading: false,
  updatedAt: new Date().getTime(),
  isError: false,
  error: undefined,
  filters: null,
  resultsCount: undefined,
  // Methods
  getRegionsForecasts: async () => {
    try {
      set({ isLoading: true });

      let body = null;

      const response = await axiosHelper.post(
        "/system/regionForecast/get",
        body
      );
      if (response.result) {
        set({
          regionForecasts: response.data,
          isLoading: false,
        });
      } else {
        set({
          regionForecasts: [],
          isLoading: false,
          isError: true,
        });
      }
      set({ updatedAt: new Date().getTime() });
    } catch (error) {
      console.log(error);
      set({ isError: true, error });
    } finally {
      set({ isLoading: false });
    }
  },

  updateTime: () => {
    set({ updatedAt: new Date().getTime() });
  },
}));

//TODO: add condition to check user logged in first
//refresh alerts
