import React from "react";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";

const StatusChart = ({ dataColors }) => {
  var SteplineChartColors = getChartColorsArray(dataColors);
  const series = [
    {
      data: [1, 1, 1, 0, 0, 1, 1, 1, -1, 0, 1],
    },
  ];
  const options = {
    stroke: {
      curve: "stepline",
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Stepline Chart",
      align: "left",
      style: {
        fontWeight: 500,
      },
    },
    markers: {
      hover: {
        sizeOffset: 4,
      },
    },
    colors: SteplineChartColors,
    yaxis: {
      labels: {
        formatter: function (value) {
          if (value === 1) return 1;
          else if (value === 0) return 0;
          else if (value === -1) return -1;
          else return "";
        },
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="line"
        height={350}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default StatusChart;
