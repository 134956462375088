import Flatpickr from "react-flatpickr";
import { useEffect } from "react";
import { useDatePickerStore } from "../../stores/dashboardOneDates";
import moment from "moment";

function DatePicker() {
  const { setSelectedDates } = useDatePickerStore((state) => state);
  const { init } = useDatePickerStore((state) => state);

  useEffect(() => {
    // Call the init method when the component is loaded
    init();
  }, []);

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate());
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 7);

  return (
    <Flatpickr
      className="form-control border-0 dash-filter-picker shadow"
      // value={[
      //   moment(selectedDates[0]).format("DD MMM YYYY"),
      //   moment(selectedDates[1]).format("DD MMM YYYY"),
      // ]}
      placeholder={"Today"}
      options={{
        mode: "range",
        dateFormat: "d M, Y",

        minDate: minDate,
        maxDate: maxDate,
        onChange: (selectedDates) => {
          if (selectedDates && selectedDates.length === 2) {
            const userOffset = new Date().getTimezoneOffset() * 60 * 1000; // Convert offset to milliseconds

            setSelectedDates([
              moment(selectedDates[0].getTime() - userOffset).format(
                "YYYY-MM-DD HH:MM:SS"
              ),
              moment(selectedDates[1].getTime() - userOffset).format(
                "YYYY-MM-DD 23:59:59"
              ),
            ]);
          }
        },
      }}
    />
  );
}

export default DatePicker;
