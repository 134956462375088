import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import { useAlertsStore } from "../../stores/Alerts";
import { useConfirmDialogStore } from "../../stores/Modal/ConfirmDialogStore";
import { TableColumnHeaderFilter } from "./../../Components/Common/DataTableBase/components/TableColumnHeaderFilter";
import { t } from "i18next";
import ExcelJS, { FormulaType } from "exceljs";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  ModalFooter,
} from "reactstrap";

import { AlertsTableFilters } from "./AlertsTableFilters";
import { AddAlert } from "./AddAlert";
import { useAuth } from "../../stores";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const AlertsPage = () => {
  const title = "BASSEERFIRE | Alerts";
  document.title = title;
  const [showAddAlertModal, setShowAddAlertModal] = useState(false);

  const toggleAddAlertModal = () => {
    setShowAddAlertModal(!showAddAlertModal);
  };

  const user = useAuth((state) => state.user);
  // API Call
  const {
    getAlerts,
    isLoading,
    isError,
    alerts,
    deleteAlertRecord,
    setToFalsePositive,
    setToTruePositive,
    filters,
    setFilters,
    resultsCount,
  } = useAlertsStore((state) => state);

  const { showConfirm } = useConfirmDialogStore((state) => state);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const fillInFilters = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setFilters({ ...filters, [inputName]: inputValue });
  };
  const downloadReportPdf = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 20;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Incident Report";
    const headers = columns
      .filter((column) => column.name !== "Photo")
      .map((column) => column.name); // Filter out the column with name "photo"

    const heading = [
      "Id",
      "Wilaya",
      "Daira",
      "Commune",
      "Foret",
      "Declaration",
      "Extinction",
      "Moyens",
      "Source",
      "Observation",
      "FP",
      "GPS",
    ];

    const data = alerts.map((elt) => {
      const coordinates = `${elt.location?.coordinates[0].toFixed(
        6
      )}, ${elt.location?.coordinates[1].toFixed(6)} \n    `;
      const latitude = elt.location?.coordinates[0];
      const longitude = elt.location?.coordinates[1];
      const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
      return [
        elt.code,
        elt.wilaya?.name,
        elt.daira,
        elt.commune,
        elt.place,

        elt.start,
        elt.end,
        elt.means,
        elt.source,
        elt.observation,
        elt.falsePositive,
        coordinates, // Use coordinates as text
      ];
    });

    let content = {
      startY: 50,
      head: [heading],
      body: data,
      cellWidth: "auto",

      didDrawCell: (data) => {
        if (data.column.dataKey === 11 && data.cell.section === "body") {
          const x = data.cell.x + 5;
          const y = data.cell.y + 15;
          const coordinatesText = data.cell.raw;
          const latitude = coordinatesText.split(",")[0];
          const longitude = coordinatesText.split(" ")[1];
          const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
          doc.setTextColor(0, 0, 255); // Set text color to blue
          doc.textWithLink("Map", x, y + 20, {
            // Adjusting the y position
            url: googleMapsUrl,
          });
          doc.setTextColor(0); // Reset text color to default
        }
      },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    doc.save("report.pdf");
  };

  const downloadReportExcl = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 40;
    sheet.getRow(1).border = {
      top: { style: "thick", color: { argb: "FFFF0000" } },
      left: { style: "thick", color: { argb: "000000FF" } },
      bottom: { style: "thick", color: { argb: "F08080" } },
      right: { style: "thick", color: { argb: "FF00FF00" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "darkVertical",
      fgColor: { argb: "FFFF00" },
    };

    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 16,
      bold: true,
    };
    sheet.columns = [
      {
        header: "Id",
        key: "code",
        width: 10,
      },
      {
        header: "Wilaya",
        key: "wilaya",
        width: 20,
      },
      {
        header: "Daira",
        key: "daira",
        width: 20,
      },
      {
        header: "Commune",
        key: "commune",
        width: 20,
      },
      {
        header: "Foret",
        key: "place",
        width: 10,
      },
      {
        header: "GPS",
        key: "gps",
        width: 30,
      },
      {
        header: "Declaration",
        key: "start",
        width: 20,
      },
      {
        header: "Extinction",
        key: "end",
        width: 20,
      },
      {
        header: "Moyens",
        key: "means",
        width: 10,
      },
      {
        header: "Source",
        key: "source",
        width: 10,
      },
      {
        header: "Observation",
        key: "observation",
        width: 20,
      },
      {
        header: "FP",
        key: "FP",
        width: 10,
      },
      {
        header: "Image",
        key: "photo",
        width: 20,
      },
    ];

    alerts.map(async (alert, index) => {
      const rowNumber = index + 1;
      const gpsUrl = `http://maps.google.com/maps?q=${alert.location?.coordinates[0]},${alert.location?.coordinates[1]}`;
      sheet.addRow({
        id: alert.code,
        wilaya: alert.wilaya?.name || alert.wilaya,
        daira: alert.daira,
        commune: alert.commune,
        place: alert.place,
        gps: gpsUrl,
        start: alert.start,
        end: alert.end,
        means: alert.means,
        source: alert.source,
        observation: alert.observation,

        FP: alert.falsePositive ? "Yes" : "No",
        photo: alert?.photo?.normal,
      });
      const gpsCell = sheet.getCell(`F${rowNumber + 1}`); // Assuming GPS column is the 6th column (column 'F')
      gpsCell.style = {
        font: { color: { argb: "FF0000FF" }, underline: true }, // Blue color with underline
      };
      gpsCell.value = {
        text: `${alert.location?.coordinates[0].toFixed(
          6
        )}, ${alert.location?.coordinates[1].toFixed(6)}`,
        hyperlink: gpsUrl,
      };
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "download.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    getAlerts(filters);
  }, []);

  useEffect(() => {
    setTotalRows(resultsCount);
  }, [alerts]);
  const toggleModal = () => setModalOpen(!modalOpen);

  const handleRowClick = (row) => {
    setSelectedRow(row);
    toggleModal();
  };

  const columns = [
    {
      name: t("Plus"),
      width: "65px",
      // selector: (row) => row.code,
      sortable: false,
      wrap: true,
      // filter: (
      //   <input
      //     value={""}
      //     // placeholder={`Filter ${column}`}
      //   />
      // ),
      cell: (row) => (
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Button
            className="bx bx-plus rounded-circle"
            color="primary"
            size="sm"
            onClick={() => handleRowClick(row)}
          ></Button>
          {/* <span>{row.code}</span> */}
        </div>
      ),
    },

    {
      name: t("Wilaya"),
      // width: "100px",
      selector: (row) => row?.wilaya?.name || row?.wilaya,
      sortable: false,
      wrap: true,
    },
    {
      name: "Daira",
      // width: "100px",
      selector: (row) => row?.daira,
      sortable: false,
      wrap: true,
    },
    {
      name: "Commune",
      // width: "100px",
      selector: (row) => row?.commune,
      sortable: false,
      wrap: true,
    },
    // {
    //   name: "Forêt ou Lieu Dit",
    //   // width: "100px",
    //   selector: (row) => row?.place,
    //   sortable: false,
    //   wrap: true,
    // },
    // {
    //   name: "GPS",
    //   width: "160px",
    //   selector: (row) => row.location?._id,
    //   sortable: false,
    //   wrap: true,
    //   format: (row) =>
    //     `lat: ${row.location?.coordinates[0]} lng: ${row.location?.coordinates[1]}`,
    //   cell: (row) => (
    //     <span>
    //       {row.location?.coordinates[0]?.toFixed(6)},
    //       {row.location?.coordinates[1]?.toFixed(6)}
    //     </span>
    //   ),
    // },

    {
      name: t("Declaration"),
      // width: "100px",
      selector: (row) => row?.start,
      sortable: false,
      wrap: true,
    },
    {
      name: t("Extinction"),
      width: "120px",
      selector: (row) => row?.end,
      sortable: false,
      wrap: true,
    },
    {
      name: t("Moyens d’intervention"),
      // width: "100px",
      selector: (row) => row?.means,
      sortable: false,
      wrap: true,
    },
    {
      name: t("Source"),
      width: "80px",
      selector: (row) => row?.source,
      sortable: false,
      wrap: true,
    },

    // {
    //   name: t("Observation"),
    //   // width: "100px",
    //   selector: (row) => row?.observation,
    //   sortable: false,
    //   wrap: true,
    // },

    {
      name: t("Photo"),
      // width: "100px",
      selector: (row) => row.photo?.small,
      sortable: false,
      wrap: true,
      cell: (row) => (
        <img
          src={row.photo?.small}
          alt="Alert"
          style={{
            height: "100%",
            width: "100%",
            padding: "10px 5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowImageModal((model) => !model);
            setImageModel(row.photo?.normal);
          }}
        />
      ),
    },
    {
      name: t("FP"),
      width: "70px",
      selector: (row) => row.falsePositive,
      sortable: false,
      wrap: true,
      format: (row) => `${row.falsePositive ? "Yes" : "No"}`,
    },
  ];

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageModel, setImageModel] = useState("");

  const [totalRows, setTotalRows] = useState(0);

  const onChangePage = (page) => {
    const newFilters = { ...filters, page: page };
    getAlerts(newFilters);
  };
  const columnsFilters = [
    {
      name: "code",
      colName: t("Incident Id"),
      onChange: fillInFilters,
      value: filters.code,
      type: "text",
    },

    {
      name: "type",
      colName: t("Type"),
      onChange: fillInFilters,
      selectOptions: [
        { value: "", displayText: "Select" },
        { value: "fire", displayText: "Fire" },
        { value: "smoke", displayText: "Smoke" },
      ],
      type: "select",
    },
    {
      name: "date",
      colName: t("Date"),
      onChange: fillInFilters,
      value: { dateFrom: "From", dateTo: "to" },
      type: "date",
    },
    {
      name: "wilaya",
      colName: t("Wilaya"),
      onChange: fillInFilters,
      value: filters.wilaya,
      type: "text",
    },
    {
      name: "devId",
      colName: t("Device Id"),
      onChange: fillInFilters,
      value: filters.devId,
      type: "text",
    },
    {
      name: "falsePositive",
      colName: t("False Positive"),
      onChange: fillInFilters,
      selectOptions: [
        { value: "", displayText: "Select" },
        { value: "true", displayText: "Yes" },
        {
          value: "false",
          displayText: "No",
        },
      ],
      type: "select",
    },
  ];

  const filterData = () => {
    getAlerts(filters);
  };
  const cancelFilter = () => {
    getAlerts({ falsePositive: false });
    setFilters({});
  };

  return (
    <>
      <ButtonGroup>
        <UncontrolledDropdown>
          <DropdownToggle tag="button" className="my-2 btn btn-soft-success">
            Download Report <i className="mdi mdi-chevron-down"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={downloadReportPdf}>PDF</DropdownItem>
            <DropdownItem onClick={downloadReportExcl}>EXCEL</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonGroup>
      {/* <button
        type="button"
        onClick={downloadReport}
        className="btn btn-soft-success my-2 text-xl-center"
      >
        <i className="ri-add-circle-line align-middle me-1"></i> Download Report
      </button> */}
      <DataTableBase
        tableTitle={
          <div className="d-flex align-items-center justify-content-between ">
            <h5>Alert</h5>
            {user?.roles?.includes("superAdmin") && (
              <button
                type="button"
                onClick={toggleAddAlertModal}
                className="btn btn-soft-danger"
              >
                <i className="ri-add-circle-line align-middle me-1"></i> Add
                Alert
              </button>
            )}
          </div>
        }
        data={alerts}
        columns={columns}
        loading={isLoading}
        paginationTotalRows={totalRows}
        onChangePage={onChangePage}
        // onChangeRowsPerPage={onChangeRowsPerPage}
        // onHeaderAddBtnClick={toggleCreateModal}
        onHeaderDeleteBtnClick={() => {
          // alert("Soon");
        }}
        // onRowEditBtnClick={setToFalsePositive}
        // onRowDeleteBtnClick={deleteAlertRecord}

        // onActivate={activateTenant}
        // onSuspend={suspendTenant}
        // onSearchIconClick={searchHandler}
        actionColWidth="100px"
        showSearch={false}
        showSubHeader={false}
        showActionButtons={false}
        showActionColumn={user?.roles?.includes("superAdmin") ? true : false}
        customActionBtns={(row) => (
          <>
            {!row.falsePositive ? (
              <button
                className="btn btn-sm btn-success"
                onClick={() => {
                  showConfirm(
                    () => {
                      setToFalsePositive(row._id);
                    },
                    () => {},
                    " ",
                    "Are you sure you want to flag this as False Positive?"
                  );
                }}
                title="False Positive"
              >
                <i className="ri-flag-fill"></i>
              </button>
            ) : (
              <button
                className="btn btn-sm btn-danger"
                onClick={() => {
                  showConfirm(
                    () => {
                      setToTruePositive(row._id);
                    },
                    () => {},
                    " ",
                    "Are you sure you want to flag this as True Positive?"
                  );
                }}
                title="True Positive"
              >
                <i className="ri-flag-fill"></i>
              </button>
            )}
            <button
              className="btn btn-sm btn-danger"
              onClick={() => {
                showConfirm(() => {
                  deleteAlertRecord(row._id);
                });
              }}
              title="Delete"
            >
              <i className="ri-delete-bin-line"></i>
            </button>
          </>
        )}
        // onApplyFilterBtnClick={() => {
        //   getAlerts(filters);
        // }}
        // onCancelFilterBtnClick={() => {
        //   getAlerts({});
        //   setFilters({});
        // }}
      >
        <AlertsTableFilters
          filters={columnsFilters}
          onFilterButtonClick={filterData}
          onCancelButtonClick={cancelFilter}
        />
      </DataTableBase>
      {user?.roles?.includes("superAdmin") && (
        <AddAlert
          toggleAddAlertModal={toggleAddAlertModal}
          showAddAlertModal={showAddAlertModal}
        />
      )}
      <Modal
        isOpen={showImageModal}
        toggle={() => {
          setShowImageModal(!showImageModal);
        }}
        centered
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            setShowImageModal(!showImageModal);
          }}
          className="p-2"
        ></ModalHeader>
        <ModalBody>
          <img
            src={imageModel}
            alt="Full Device"
            style={{
              maxHeight: "60vh",
              width: "100%",
            }}
          />
        </ModalBody>
      </Modal>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Details for Incident No. {selectedRow?.code}
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>wilaya:</strong> {selectedRow?.wilaya?.name}
          </p>
          <p>
            <strong>daira:</strong> {selectedRow?.daira}
          </p>
          <p>
            <strong>commune:</strong> {selectedRow?.commune}
          </p>
          <p>
            <strong>Forest:</strong> {selectedRow?.place}
          </p>
          <p>
            <strong>GPS:</strong>{" "}
            {selectedRow?.location?.coordinates[0]?.toFixed(6)},
            {selectedRow?.location?.coordinates[1]?.toFixed(6)}
          </p>
          <p>
            <strong>Declaration:</strong> {selectedRow?.start}
          </p>
          <p>
            <strong>Extinction:</strong> {selectedRow?.end}
          </p>
          <p>
            <strong>Moyens:</strong> {selectedRow?.means}
          </p>
          <p>
            <strong>Source:</strong> {selectedRow?.source}
          </p>
          <p>
            <strong>Observation:</strong> {selectedRow?.observation}
          </p>
          <div>
            <img
              style={{
                height: "100%",
                width: "100%",
                padding: "10px 5px",
                cursor: "pointer",
              }}
              src={selectedRow?.photo?.small}
            />
          </div>
          {/* Add more details as needed */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/* <div style={{ width: "50vw" }}>
        <ModalDataDatatables />
      </div> */}
    </>
  );
};

// export const tableHeader = (toggleAddAlertModal) => {
//   return (
//     <div className="d-flex align-items-center justify-content-between ">
//       <h5>Alert</h5>
//       <button
//         style={{}}
//         onClick={toggleAddAlertModal()}
//         className="border-0 p-2 rounded-2 bg-danger text-bg-danger"
//       >
//         New Alert
//       </button>
//     </div>
//   );
// };
