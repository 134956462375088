import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";
import { useUpdatedStore } from "./UpdatedStore"; // Adjust the import path as needed

export const useWilayasStore = create((set, get) => ({
  wilayas: [],
  cities: [],
  updatedAt: new Date().getTime(),
  isLoading: false,
  isError: false,
  error: undefined,
  filters: null,

  resultsCount: undefined,
  // Methods
  getWilayas: async (filters) => {
    // set({ filters: filterNonFalseValues(filters) });

    const body = {
      search: filterNonFalseValues(filters)?.search,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      //   page: filters?.page || 1,
      //   limit: filters?.perPage || 10,
    };

    try {
      set({ isLoading: true });
      let response = await axiosHelper.post("/system/wilaya/get", body);

      if (!response.result) {
        return;
      }

      set({
        wilayas: response.data,
        resultsCount: response.count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  getCities: async (filters) => {
    // set({ filters: filterNonFalseValues(filters) });

    const body = {
      search: filterNonFalseValues(filters)?.search,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      //   page: filters?.page || 1,
      //   limit: filters?.perPage || 10,
    };

    try {
      set({ isLoading: true });

      let response = await axiosHelper.post("/system/city/get", body);

      if (!response.result) {
        return;
      }

      set({
        cities: response.data,
        resultsCount: response.count,
        isLoading: false,
      });

      set({ updatedAt: new Date().getTime() });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  updateTime: () => {
    set({ updatedAt: new Date().getTime() });
  },
}));

//TODO: add condition to check user logged in first
//refresh alerts
