import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import ExcelJS, { FormulaType } from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import StatusChart from "./Charts/StatusChart";
import DifferentColor from "./Charts/DifferentColor";
import { useDevicesStore } from "../../stores/Devices";
import Section from "../../Components/Common/Section";
import { useDateHealthStore } from "../../stores/DeviceHealthMonitorDates";
import { t } from "i18next";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";

const MonitorDevice = () => {
  const [selectedDeviceLabel, setSelectedDeviceLabel] = useState("DGF0001");
  const [selectedDevId, setDeviceId] = useState("");
  const [devicesOptions, setDevicesOptions] = useState([]);
  const [onlineData, setOnlineData] = useState([]);
  const [offlineData, setOfflineData] = useState([]);
  const [warningData, setWarningData] = useState([]);
  const { devices, getDevices, healthData, getDeviceHealth } = useDevicesStore(
    (state) => state
  );
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const { healthDates } = useDateHealthStore((state) => state);
  useEffect(() => {
    getDevices && getDevices();
    getDeviceHealth && getDeviceHealth();
  }, []);
  useEffect(() => {
    if (healthDates.length == 2) {
      getDeviceHealth({ dateFrom: healthDates[0], dateTo: healthDates[1] });
    }
  }, [healthDates]);
  useEffect(() => {
    if (devices.length > 0) {
      let array = [];
      devices.map((dev) => {
        if (dev.type === "windTemp")
          array.push({
            label: dev?.devId,
            value: dev._id,
          });
      });
      const sortedarr = array.reverse();
      setDevicesOptions(sortedarr);
      setSelectedDeviceLabel(sortedarr[0]?.label);
      setDeviceId(sortedarr[0]?.value);
    }
  }, [devices]);
  useEffect(() => {
    if (selectedDevId) {
      let arrOn = [];
      let arrOff = [];
      let arrWarning = [];
      healthData.map((d) => {
        if (d.devId === selectedDevId) {
          let date = new Date(d.createdAt).getTime();
          if (d.status === "online") {
            arrOn.push(date);
          } else if (d.status === "offline") {
            arrOff.push(date);
          } else {
            arrWarning.push(date);
          }
        }
      });
      arrOn.sort((a, b) => a - b);
      arrOff.sort((a, b) => a - b);
      arrWarning.sort((a, b) => a - b);
      arrOn = arrOn.length > 0 ? [arrOn[0], arrOn[arrOn.length - 1]] : [];
      arrOff = arrOff.length > 0 ? [arrOff[0], arrOff[arrOff.length - 1]] : [];
      arrWarning =
        arrWarning.length > 0
          ? [arrWarning[0], arrWarning[arrWarning.length - 1]]
          : [];
      //fetch the history or logs or anything that has the status of that device on it
      setOnlineData(arrOn);
      setOfflineData(arrOff);
      setWarningData(arrWarning);
    }
  }, [selectedDevId, healthData]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleRowClick = (row) => {
    setSelectedRow(row);
    toggleModal();
  };
  const columns = [
    {
      name: t("Plus"),
      width: "65px",
      // selector: (row) => row.code,
      sortable: false,
      wrap: true,
      // filter: (
      //   <input
      //     value={""}
      //     // placeholder={`Filter ${column}`}
      //   />
      // ),
      cell: (row) => (
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Button
            className="bx bx-plus rounded-circle"
            color="primary"
            size="sm"
            onClick={() => handleRowClick(row)}
          ></Button>
        </div>
      ),
    },

    {
      name: t("DevId"),
      width: "150px",
      selector: (row) => row?.devId,
      sortable: false,
      wrap: true,
    },
    {
      name: "STATUS",
      width: "110px",
      selector: (row) => row?.status,
      sortable: false,
      wrap: true,
    },
    {
      name: "TS",
      width: "180px",
      selector: (row) => row?.ts,
      sortable: false,
      wrap: true,
    },

    {
      name: t("GPU"),
      width: "110px",
      selector: (row) => row?.gpu,
      sortable: false,
      wrap: true,
    },
    {
      name: t("CPU"),
      width: "110px",
      selector: (row) => row?.cpu,
      sortable: false,
      wrap: true,
    },
    {
      name: t("RAM"),
      width: "110px",
      selector: (row) => row?.ram,
      sortable: false,
      wrap: true,
    },
    {
      name: t("CPU TEMP"),
      width: "130px",
      selector: (row) => row?.cpu_temp,
      sortable: false,
      wrap: true,
    },

    {
      name: t("HDD"),
      width: "110px",
      selector: (row) => row?.hdd,
      sortable: false,
      wrap: true,
    },

    {
      name: t("HDD Percent"),
      width: "130px",
      selector: (row) => row?.hdd_percent,
      sortable: false,
      wrap: true,
    },
    {
      name: t("Battery"),
      width: "120px",
      selector: (row) => row?.bat,
      sortable: false,
      wrap: true,
    },
  ];

  const downloadReportPdf = () => {
    // const unit = "pt";
    // const size = "A4"; // Use A1, A2, A3 or A4
    // const orientation = "landscape"; // portrait or landscape
    // const marginLeft = 20;
    // const doc = new jsPDF(orientation, unit, size);
    // doc.setFontSize(15);
    // const title = "Incident Report";
    // const headers = columns
    //   .filter((column) => column.name !== "Photo")
    //   .map((column) => column.name); // Filter out the column with name "photo"
    // const heading = [
    //   "Id",
    //   "Wilaya",
    //   "Daira",
    //   "Commune",
    //   "Foret",
    //   "Declaration",
    //   "Extinction",
    //   "Moyens",
    //   "Source",
    //   "Observation",
    //   "FP",
    //   "GPS",
    // ];
    // const data = alerts.map((elt) => {
    //   const coordinates = `${elt.location?.coordinates[0].toFixed(
    //     6
    //   )}, ${elt.location?.coordinates[1].toFixed(6)} \n    `;
    //   const latitude = elt.location?.coordinates[0];
    //   const longitude = elt.location?.coordinates[1];
    //   const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    //   return [
    //     elt.code,
    //     elt.wilaya?.name,
    //     elt.daira,
    //     elt.commune,
    //     elt.place,
    //     elt.start,
    //     elt.end,
    //     elt.means,
    //     elt.source,
    //     elt.observation,
    //     elt.falsePositive,
    //     coordinates, // Use coordinates as text
    //   ];
    // });
    // let content = {
    //   startY: 50,
    //   head: [heading],
    //   body: data,
    //   cellWidth: "auto",
    //   didDrawCell: (data) => {
    //     if (data.column.dataKey === 11 && data.cell.section === "body") {
    //       const x = data.cell.x + 5;
    //       const y = data.cell.y + 15;
    //       const coordinatesText = data.cell.raw;
    //       const latitude = coordinatesText.split(",")[0];
    //       const longitude = coordinatesText.split(" ")[1];
    //       const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    //       doc.setTextColor(0, 0, 255); // Set text color to blue
    //       doc.textWithLink("Map", x, y + 20, {
    //         // Adjusting the y position
    //         url: googleMapsUrl,
    //       });
    //       doc.setTextColor(0); // Reset text color to default
    //     }
    //   },
    // };
    // doc.text(title, marginLeft, 40);
    // doc.autoTable(content);
    // doc.save("report.pdf");
  };

  const downloadReportExcl = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 40;
    sheet.getRow(1).border = {
      top: { style: "thick", color: { argb: "FFFF0000" } },
      left: { style: "thick", color: { argb: "000000FF" } },
      bottom: { style: "thick", color: { argb: "F08080" } },
      right: { style: "thick", color: { argb: "FF00FF00" } },
    };
    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "darkVertical",
      fgColor: { argb: "FFFF00" },
    };
    sheet.getRow(1).font = {
      name: "Comic Sans MS",
      family: 4,
      size: 16,
      bold: true,
    };
    sheet.columns = [
      {
        header: "Id",
        key: "devId",
        width: 20,
      },
      {
        header: "Status",
        key: "status",
        width: 20,
      },
      {
        header: "CPU",
        key: "cpu",
        width: 10,
      },
      {
        header: "GPU",
        key: "gpu",
        width: 10,
      },
      {
        header: "RAM",
        key: "ram",
        width: 10,
      },
      {
        header: "CPU Temp",
        key: "cpu_temp",
        width: 20,
      },
      {
        header: "HDD",
        key: "hdd",
        width: 10,
      },
      {
        header: "HDD_PERCENT",
        key: "hdd_percent",
        width: 20,
      },
      {
        header: "Battery",
        key: "bat",
        width: 10,
      },
    ];
    healthData.map(async (alert, index) => {
      sheet.addRow({
        devId: alert.devId,
        status: alert.status,
        cpu: alert.cpu,
        gpu: alert.gpu,
        ram: alert.ram,
        cpu_temp: alert.cpu_temp,
        hdd: alert.hdd,
        hdd_percent: alert.hdd_percent,
        bat: alert.bat,
      });
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "download.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <div>
      <Section
        setSelectedDeviceLabel={setSelectedDeviceLabel}
        devicesOptions={devicesOptions}
      />

      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Device Status Chart </h4>
            </CardHeader>
            <CardBody>
              <DifferentColor
                onlineData={onlineData}
                offlineData={offlineData}
                warningData={warningData}
                dataColors='["--vz-primary", "--vz-danger", "--vz-success", "--vz-warning", "--vz-info"]'
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <ButtonGroup>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="button"
                className="my-2 btn btn-soft-success"
              >
                Download Report <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={downloadReportPdf}>PDF</DropdownItem>
                <DropdownItem onClick={downloadReportExcl}>EXCEL</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ButtonGroup>
          <DataTableBase
            tableTitle={
              <div className="d-flex align-items-center justify-content-between ">
                <h5>Devices Health</h5>
              </div>
            }
            data={[
              {
                bat: 0.5,
                cpu_temp: 60,
                cpu: 0.7,
                gpu: 0.7,
                ram: 0.7,
                hdd: 400,
                hdd_percent: 80,
                status: "warning",
                ts: "2024-05-24 8:21:00",
                devId: "DGF0001",
              },
            ]}
            columns={columns}
            // loading={isLoading}
            // paginationTotalRows={totalRows}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeRowsPerPage}
            // onHeaderAddBtnClick={toggleCreateModal}
            onHeaderDeleteBtnClick={() => {
              // alert("Soon");
            }}
            // onRowEditBtnClick={setToFalsePositive}
            // onRowDeleteBtnClick={deleteAlertRecord}

            // onActivate={activateTenant}
            // onSuspend={suspendTenant}
            // onSearchIconClick={searchHandler}

            showActionColumn={false}
            showSearch={false}
            showSubHeader={false}
            showActionButtons={false}

            // onApplyFilterBtnClick={() => {
            //   getAlerts(filters);
            // }}
            // onCancelFilterBtnClick={() => {
            //   getAlerts({});
            //   setFilters({});
            // }}
          ></DataTableBase>
          <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
              Health Details for Device No. {selectedRow?.code}
            </ModalHeader>
            <ModalBody>
              <p>
                <strong>wilaya:</strong> {selectedRow?.wilaya?.name}
              </p>
              <p>
                <strong>daira:</strong> {selectedRow?.daira}
              </p>
              <p>
                <strong>commune:</strong> {selectedRow?.commune}
              </p>
              <p>
                <strong>Forest:</strong> {selectedRow?.place}
              </p>
              <p>
                <strong>GPS:</strong>{" "}
                {selectedRow?.location?.coordinates[0]?.toFixed(6)},
                {selectedRow?.location?.coordinates[1]?.toFixed(6)}
              </p>
              <p>
                <strong>Declaration:</strong> {selectedRow?.start}
              </p>
              <p>
                <strong>Extinction:</strong> {selectedRow?.end}
              </p>
              <p>
                <strong>Moyens:</strong> {selectedRow?.means}
              </p>
              <p>
                <strong>Source:</strong> {selectedRow?.source}
              </p>
              <p>
                <strong>Observation:</strong> {selectedRow?.observation}
              </p>
              <div>
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "10px 5px",
                    cursor: "pointer",
                  }}
                  src={selectedRow?.photo?.small}
                />
              </div>
              {/* Add more details as needed */}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default MonitorDevice;
