import React, { useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "./BreadCrumb";
import { useHomeDashboardStore } from "../../../stores/HomeDashboard";
import Widget from "../Dashboard1/Widget";
import { t } from "i18next";
import InfoCard from "./InfoCard";
import SiteCard from "./components/SiteCard";
import { usesitesStore } from "../../../stores/Sites";
import { useMonitoringStatusStore } from "../../../stores/MonitoringStatus";
import { useDashboardTwoStore } from "../../../stores/DashboardTwo";
import Loader from "../../../Components/Common/Loader";
import moment from "moment";

//this dashboard might change

export default function DashboardTwo() {
  const title = "BASSEERFIRE | Dashboard2";
  document.title = title;
  const { fetchLatestPerSite, sitesData } = useMonitoringStatusStore(
    (state) => state
  );
  const {
    siteHistoryForLast,
    alternatives,
    sitesHistoryData,
    updatedAt,
    updateTime,
  } = useDashboardTwoStore((state) => state);
  useEffect(() => {
    // Fetch data initially
    fetchLatestPerSite && fetchLatestPerSite();
    siteHistoryForLast && siteHistoryForLast({ interval: "1" });

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(() => {
      fetchLatestPerSite && fetchLatestPerSite();
      siteHistoryForLast && siteHistoryForLast({ interval: "1" });
      updateTime();
    }, 300000); // 5 minutes in milliseconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {}, [sitesHistoryData, alternatives]);

  // useEffect(() => {}, [sitesData]);
  return (
    <React.Fragment>
      <BreadCrumb title={title} pageTitle={"Dashboard"} />
      <small className="text-muted ">
        Last Update: {moment(updatedAt).format("YYYY-MM-DD hh:mm:ss")}
      </small>
      {sitesData?.length === 0 ? (
        <div
          style={{ height: "70vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loader />
        </div>
      ) : (
        <Row style={{ width: "100%" }} className=" m-auto">
          {sitesData?.length > 0 &&
            (sitesHistoryData?.length > 0 || alternatives?.length > 0) &&
            sitesData.map((data) => {
              //retrieve only datapoints of that site
              const matchingSiteHistory = sitesHistoryData.filter(
                (site) => site.siteId === data.siteInfo._id
              );
              //retrieve alternative datapoints of that site
              const uniqueAlt = alternatives.filter(
                (alt) => alt.siteId === data.siteInfo._id
              );
              return (
                <Col lg={6} xxl={6} key={data.siteInfo.code} className="">
                  <SiteCard
                    alternative={uniqueAlt[0]}
                    series={matchingSiteHistory ? matchingSiteHistory : null}
                    info={data}
                  />
                </Col>
              );
            })}
        </Row>
      )}
    </React.Fragment>
  );
}
