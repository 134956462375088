import moment from "moment";
import { create } from "zustand";

export const useDateHealthStore = create((set, get) => ({
  healthDates: [],
  isLoading: false,
  isError: false,

  // Methods
  setHealthDates: async (body) => {
    try {
      set({
        healthDates: body,

        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  // init: () => {
  //   const today = new Date();
  //   const formattedDate = `${today.getFullYear()}-${String(
  //     today.getMonth() + 1
  //   ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  //   set({ HealthDates: [formattedDate, formattedDate] });
  // },

  init: () => {
    // Start of day

    const userOffset = new Date().getTimezoneOffset() * 60 * 1000; // Convert offset to milliseconds

    let startOfDay = new Date();

    // End of day
    let endOfDay = new Date();

    // Set the selected dates
    set({
      healthDates: [
        moment(startOfDay.getTime() + userOffset).format("YYYY-MM-DD 00:00:00"),
        moment(endOfDay.getTime() + userOffset).format("YYYY-MM-DD 23:59:59"),
      ],
    });
  },
}));
