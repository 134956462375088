/* eslint-disable no-debugger */
import { create } from "zustand";
import { axiosHelper } from "./helpers";

export const useHomeDashboardStore = create((set, get) => ({
  data: [],
  isLoading: false,
  isError: false,
  error: undefined,

  // Methods
  getDashboardData: async () => {
    try {
      set({ isLoading: true });
      let response = await axiosHelper.get("/system/dashboard/get/dashboard");

      if (!response.result) {
        return;
      }
      set({ data: response.data });
      // console.log(response.data);
      return response.data;

    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
