import moment from "moment";
import { create } from "zustand";

export const useDatePickerStore = create((set, get) => ({
  selectedDates: [],
  isLoading: false,
  isError: false,

  // Methods
  setSelectedDates: async (body) => {
    try {
      set({
        selectedDates: body,

        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },

  init: () => {
    const userOffset = new Date().getTimezoneOffset() * 60 * 1000; // Convert offset to milliseconds

    let startOfDay = new Date();

    // End of day
    let endOfDay = new Date();

    // Set the selected dates
    set({
      selectedDates: [
        moment(startOfDay.getTime() + userOffset).format("YYYY-MM-DD 00:00:00"),
        moment(endOfDay.getTime() + userOffset).format("YYYY-MM-DD 23:59:59"),
      ],
    });
  },
}));
