import React, { useEffect, useMemo, useState } from "react";
import "./jquery-jvectormap.scss";
import "./leafletstyling.scss";
import { useDevicesStore } from "../../../../stores/Devices";
import DevicePopup from "./DevicePopup";
import { usesitesStore } from "../../../../stores/Sites";
import { useAlertsStore } from "../../../../stores/Alerts";
import greenState from "../../../../assets/imgs/states/map_icon_green.png";
import redState from "../../../../assets/imgs/states/map_icon_red.png";
import grayState from "../../../../assets/imgs/states/map_icon_gray.png";
import orangeState from "../../../../assets/imgs/states/map_icon_orange.png";
import { Marker, MapContainer, TileLayer, Popup } from "react-leaflet";
import { Icon, divIcon } from "leaflet";
import { useSelector } from "react-redux";

// import x from "../../../../assets/images/bg-d.png"
const Vectormap = (props) => {
  const map = React.createRef(null);
  // const { getDevices, devices } = useDevicesStore((state) => state);
  const { getSites, sites } = usesitesStore((state) => state);
  const { mapAlerts, getAlerts } = useAlertsStore((state) => state);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [alertMarkers, setAlertMarkers] = useState([]);
  const [siteMarkers, setSiteMarkers] = useState([]);
  const [allMarkers, setAllMarkers] = useState([]);
  const { layoutModeType } = useSelector((state) => ({
    layoutModeType: state.Layout.layoutModeType,
  }));
  useEffect(() => {
    // getDevices();
    getSites && getSites();
    getAlerts && getAlerts();
  }, []);

  useEffect(() => {
    if (!sites?.length) return;
  }, [sites]);

  const getMarkerColor = (status30, isDangerous) => {
    if (isDangerous || status30 === "danger") return redState;
    else return greenState;
  };

  const handleMarkerClick = (e, code) => {
    // Set the selected marker when a marker is clicked
    setSelectedMarker(sites[code]);
    setShowDeviceModal(!showDeviceModal);
  };

  useEffect(() => {
    if (sites.length > 0) {
      const newMarkers = sites.map((site, indx) => ({
        key: indx,
        type: "site",
        latLng: site?.location?.coordinates?.slice(0, 2),
        alert: false,
        icon: new Icon({
          iconUrl: getMarkerColor(site?.status30, site?.isDangerous),
          iconSize: [18, 18],
        }),
      }));
      setSiteMarkers(newMarkers);
    }
  }, [sites]);

  useEffect(() => {
    if (mapAlerts.length > 0 && Object.keys(mapAlerts[0]).length > 0) {
      const newAlertMarkers =
        mapAlerts.length > 0
          ? mapAlerts.map((alert, indx) => ({
              key: indx,
              type: "alert",
              latLng: alert?.location?.coordinates,
              alert: true,
              icon: new divIcon({
                iconSize: [20, 20],
                iconAnchor: [15, 15],
                className: "alert-icon",
              }),
            }))
          : [];
      setAlertMarkers(newAlertMarkers);
    }
  }, [mapAlerts]);

  useEffect(() => {
    setAllMarkers([...alertMarkers, ...siteMarkers]);
  }, [siteMarkers, alertMarkers]);
  useEffect(() => {}, [allMarkers]);
  return (
    <>
      <div
        className={`${
          layoutModeType === "dark" && props.mapMode === "normal"
            ? "leaflet-darkmode"
            : ""
        } map-main relative`}
        style={{ width: props.width, height: 500 }}
      >
        <MapContainer
          center={[36.37377988878743, 3.8951729813480367]}
          zoom={7}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url={props.layer}
          />
          {/**"https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"  //satellite*/}
          {allMarkers.length > 0 &&
            allMarkers.map((marker, indx) => (
              <Marker
                eventHandlers={{
                  click: (e) => {
                    if (marker?.type === "site") {
                      setSelectedMarker(sites[marker.key]);
                      setShowDeviceModal(!showDeviceModal);
                    }
                  },
                }}
                icon={marker?.icon}
                key={indx}
                position={[marker?.latLng[0], marker?.latLng[1]]}
              >
                {/* <Popup>
      A pretty CSS3 popup. <br /> Easily customizable.
    </Popup> */}
              </Marker>
            ))}
        </MapContainer>

        {selectedMarker && (
          <DevicePopup
            selectedMarker={selectedMarker}
            showDeviceModal={showDeviceModal}
            handleMarkerClick={handleMarkerClick}
          />
        )}
      </div>
    </>
  );
};

export default Vectormap;
