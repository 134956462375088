import { max } from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";
import { useDashboardStore } from "../../../stores/DashboardOne";

const HighestWidget = () => {
  const { temperature, wind, isLoading } = useDashboardStore((state) => state);
  const [maxTemperature, setMaxTemperature] = useState(null);
  const [maxHumidity, setMaxHumidity] = useState(null);
  const [maxWindSpeed, setMaxWindSpeed] = useState(null);

  useEffect(() => {
    if (!isLoading && temperature.length > 0 && wind.length > 0) {
      const maxTemp = Math.max(...temperature.map((data) => data.temperature));
      const maxHumidity = Math.max(...temperature.map((data) => data.humidity));
      const maxWindSpeed = Math.max(...wind.map((data) => data.speed));
      setMaxWindSpeed(maxWindSpeed);
      setMaxHumidity(maxHumidity);
      setMaxTemperature(maxTemp);
    }
  }, [temperature, isLoading, wind]);
  return (
    <React.Fragment>
      <Row className="mt-2">
        <Card className="text-center  ">
          <CardHeader>
            <h2>Max Aujourd’hui</h2>
          </CardHeader>
          <CardBody className=" d-flex align-items-center flex-row p-0">
            <Col className="p-0">
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <span className="counter-value" data-target="36.48">
                    <CountUp
                      start={0}
                      end={maxTemperature}
                      decimals={0}
                      suffix="°"
                      duration={4}
                    />
                  </span>
                </h5>
                <p className="text-muted mb-0">Temp</p>
              </div>
            </Col>
            <Col className="p-0">
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <span className="counter-value" data-target="92.54">
                    <CountUp
                      start={0}
                      end={maxHumidity}
                      decimals={0}
                      suffix="%"
                      duration={4}
                    />
                  </span>
                </h5>
                <p className="text-muted mb-0">Humidity</p>
              </div>
            </Col>
            <Col className="p-0">
              <div className="p-3 border border-dashed border-end-0">
                <h5 className="mb-1">
                  <span className="counter-value" data-target="8.62">
                    <CountUp
                      start={0}
                      end={maxWindSpeed}
                      decimals={0}
                      suffix="kmh"
                      duration={4}
                    />
                  </span>
                </h5>
                <p className="text-muted mb-0">Wind</p>
              </div>
            </Col>
          </CardBody>
        </Card>
      </Row>
    </React.Fragment>
  );
};

export default HighestWidget;
