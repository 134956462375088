import React, { useEffect, useState } from "react";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import { t } from "i18next";
import { useDevicesStore } from "../../stores/Devices";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { AddDevice } from "./AddDevice";
import { EditDevice } from "./EditDevice";
import { useLocation } from "react-router-dom";

export const DevicesTablePage = () => {
  const title = "BASSEERFIRE | Devices";
  document.title = title; // API Call
  const location = useLocation();
  const deviceId = new URLSearchParams(location.search).get("deviceId");
  const {
    getDevices,
    isLoading,
    isError,
    devices,
    deleteDevice,
    resultsCount,
  } = useDevicesStore((state) => state);
  const selectDeviceColor = (status) => {
    switch (status) {
      case "online":
        return "success";
      case "offline":
        return "danger";
      case "warning":
        return "warning";
    }
  };
  useEffect(() => {
    if (deviceId) {
      getDevices({ search: deviceId });
    }
  }, [deviceId]);
  useEffect(() => {
    getDevices();
  }, []);

  useEffect(() => {
    setTotalRows(resultsCount);
  }, [resultsCount]);
  const columns = [
    {
      name: t("Id"),
      // width: "100px",
      selector: (row) => row.devId,
      sortable: true,
      wrap: true,
    },
    {
      name: t("Status"),
      width: "95px",
      selector: (row) => row.status,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div className="cursor-normal" id={`anchor-${row.code}`}>
          <span
            className={`badge   bg-soft-${selectDeviceColor(
              row.status
            )} text-${selectDeviceColor(row.status)} text-uppercase`}
          >
            {row.status}
          </span>
          <UncontrolledTooltip placement="top" target={`anchor-${row.code}`}>
            {" "}
            {row.statusDetails}{" "}
          </UncontrolledTooltip>
        </div>
        //  <span>
        //     {row.location?.coordinates[0]?.toFixed(6)},
        //     {row.location?.coordinates[1]?.toFixed(6)}
        //   </span>
      ),
    },
    // {
    //   name: t("Status Details"),
    //   // width: "100px",
    //   selector: (row) => row.statusDetails,
    //   sortable: false,
    //   wrap: true,
    // },
    {
      name: t("Label"),
      // width: "100px",
      selector: (row) => row.label,
      sortable: true,
      wrap: true,
    },
    {
      name: t("Type"),
      // width: "100px",
      selector: (row) => row.type,
      sortable: true,
      wrap: true,
    },
    {
      name: t("Location"),
      width: "190px",
      selector: (row) => row.location?._id,
      sortable: true,
      wrap: true,
      // format: (row) =>
      //   `lat: ${row.location?.coordinates[0]} lng: ${row.location?.coordinates[1]}`,
      cell: (row) => (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${row.location?.coordinates[0]?.toFixed(
            6
          )},${row.location?.coordinates[1]?.toFixed(6)}`}
          target="_blank"
          className="link-style"
          rel="noopener noreferrer"
        >
          <strong>
            {row.location?.coordinates[0]?.toFixed(6)},{" "}
            {row.location?.coordinates[1]?.toFixed(6)}
          </strong>
        </a>
        //  <span>
        //     {row.location?.coordinates[0]?.toFixed(6)},
        //     {row.location?.coordinates[1]?.toFixed(6)}
        //   </span>
      ),
    },
    {
      name: t("Version"),
      width: "100px",
      selector: (row) => row.version,
      sortable: true,
      wrap: true,
    },
    {
      name: t("Wilaya "),
      // width: "100px",
      selector: (row) => (row?.wilaya?.name ? row?.wilaya?.name : row?.wilaya),
      sortable: true,
      wrap: true,
    },
    {
      name: t("Daira"),
      // width: "100px",
      selector: (row) => row.daira,
      sortable: false,
      wrap: true,
    },
    {
      name: t("Commune"),
      // width: "100px",
      selector: (row) => row.commune,
      sortable: false,
      wrap: true,
    },
  ];

  const searchHandler = (searchText) => {
    getDevices({
      search: searchText,
    });
  };

  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);
  const [showEditDeviceModal, setShowEditDeviceModal] = useState(false);
  const toggleAddDeviceModal = () => {
    setShowAddDeviceModal(!showAddDeviceModal);
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const toggleEditDeviceModal = (row) => {
    setSelectedRow(row);
    setShowEditDeviceModal(!showEditDeviceModal);
  };
  const [totalRows, setTotalRows] = useState(0);

  const onChangePage = (page) => {
    getDevices({
      page: page,
    });
  };
  return (
    <>
      <DataTableBase
        tableTitle={title}
        data={devices}
        columns={columns}
        loading={isLoading}
        paginationTotalRows={totalRows}
        onChangePage={onChangePage}
        // onChangeRowsPerPage={onChangeRowsPerPage}
        onHeaderAddBtnClick={toggleAddDeviceModal}
        onHeaderDeleteBtnClick={() => {
          alert("Soon");
        }}
        onRowEditBtnClick={toggleEditDeviceModal}
        onRowDeleteBtnClick={deleteDevice}
        onSearchIconClick={searchHandler}
        actionColWidth="100px"
        showSearch={true}
        showSubHeader={true}
        showActionButtons={true}
      />

      <AddDevice
        toggleAddDeviceModal={toggleAddDeviceModal}
        showAddDeviceModal={showAddDeviceModal}
      />
      {selectedRow && (
        <EditDevice
          toggleModal={toggleEditDeviceModal}
          showModal={showEditDeviceModal}
          rowData={selectedRow}
        />
      )}
    </>
  );
};
