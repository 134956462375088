/* global google */

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { RenderFormikInput } from "./../../Components/Common/Forms/FormikInputHelper";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useDevicesStore } from "../../stores/Devices";
import _ from "lodash";
import { useWilayasStore } from "../../stores/Wilayas";
export const AddDevice = ({ showAddDeviceModal, toggleAddDeviceModal }) => {
  const formik = useFormik({
    initialValues: {
      devId: "",
      label: "",
      type: "",
      version: "",
      wilaya: "",
      daira: "",
      commune: "",
      lat: "",
      lng: "",
    },
    validationSchema: Yup.object({
      devId: Yup.string().required(t("Required")),
      label: Yup.string().required(t("Required")),
      type: Yup.string().required(t("Required")),
      version: Yup.string().required(t("Required")),
      // location: Yup.array().required(t("Required")),
      wilaya: Yup.string().required(t("Required")),
      commune: Yup.string().required(t("Required")),
      daira: Yup.string().required(t("Required")),
      lat: Yup.string().required(t("Required")),
      lng: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      const body = {
        ...values,
        location: [+values.lat, +values.lng],
      };
      //omit lat lng from body
      const result = await createDevice(_.omit(body, ["lat", "lng"]));
      if (result) {
        formik.resetForm();
        toggleAddDeviceModal();
        resetStates();
      }
    },
  });
  const [selectedType, setSelectedType] = useState("");
  const [selectedWilaya, setSelectedWilaya] = useState("");
  const [wilayaList, setWilayaList] = useState([]);
  const { getWilayas, wilayas } = useWilayasStore((state) => state);

  useEffect(() => {
    getWilayas && getWilayas();
  }, []);

  useEffect(() => {
    const neededWilayaAttributes = wilayas.map((wilaya) => {
      return {
        label: `${wilaya.wilayaId}-${wilaya.name}`,
        value: wilaya._id,
      };
    });

    setWilayaList(neededWilayaAttributes);
  }, [wilayas]);

  const fieldsToRender = [
    { fieldName: "devId", label: "Device Id", fullWidth: false },

    { fieldName: "label", label: "Label", fullWidth: false },
    {
      fieldName: "type",
      label: "Type",
      placeholder: t("Select"),
      type: "select",
      options: [{ value: "camera", label: "Camera" }],
      value: selectedType,
      onChange: (option) => {
        setSelectedType(option);
      },
    },

    { fieldName: "version", label: "Version", fullWidth: false },
    {
      fieldName: "wilaya",
      label: "Wilaya",
      placeholder: t("Select"),
      type: "select",
      options: wilayaList,
      value: selectedWilaya,
      onChange: (option) => {
        setSelectedWilaya(option);
      },
    },
    { fieldName: "daira", label: "Daira", fullWidth: false },
    { fieldName: "commune", label: "Commune", fullWidth: false },
    { fieldName: "lat", label: "Latitude", fullWidth: false },
    {
      fieldName: "lng",
      label: "Longitude",
      fullWidth: false,
      inputProps: {
        onBlur: () => {
          const lat = formik.values.lat;
          const lng = formik.values.lng;

          if (lat && lng) {
            setMarkerPosition({
              lat: +lat,
              lng: +lng,
            });
          }
        },
      },
    },
  ];
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: "YOUR_API_KEY",
  });
  const center = {
    lat: -3.745,
    lng: -38.523,
  };
  const [markerPosition, setMarkerPosition] = useState(center);
  const { createDevice, isLoading } = useDevicesStore((state) => state);
  const resetStates = () => {
    setSelectedType(null);
    setSelectedWilaya(null);
    setMarkerPosition(center);
  };
  return (
    <Modal isOpen={showAddDeviceModal} toggle={toggleAddDeviceModal}>
      <ModalHeader toggle={toggleAddDeviceModal}>
        {t("Add New Device")}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} className="d-flex flex-wrap">
          {fieldsToRender.map((field) => (
            <div
              key={field.fieldName}
              className={`flex-fill mb-2 ${field.fullWidth ? "w-100" : ""}`}
              style={field.fullWidth ? {} : { width: "48%", marginRight: 2 }}
            >
              {RenderFormikInput(formik, field)}
            </div>
          ))}

          <Label htmlFor={"location"} className="w-100 form-label mt-1">
            {t("Location")}
          </Label>

          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "200px",
            }}
            center={center}
            zoom={10}
            // onLoad={onLoad}
            // onUnmount={onUnmount}
          >
            <Marker
              position={markerPosition}
              // onClick={() => {
              //   handleMarkerClick(markerData);
              // }}
              draggable={true}
              onDragEnd={(info) => {
                const lat = info.latLng.lat();
                const lng = info.latLng.lng();
                setMarkerPosition({
                  lat: lat,
                  lng: lng,
                });
                formik.setFieldValue("lat", lat);
                formik.setFieldValue("lng", lng);
              }}
            />
          </GoogleMap>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          onClick={formik.handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size={"sm"} /> : <span>{t("Add")}</span>}
        </Button>
        <Button
          color="danger"
          onClick={() => {
            toggleAddDeviceModal();
            formik.resetForm();
            resetStates();
          }}
          disabled={isLoading}
        >
          {t("Cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
