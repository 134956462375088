import React, { useEffect, useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import styled from "styled-components";
import { capitalizeFirstCharacter } from "../../stores/helpers/Utlis";

const StyledInfoWindow = styled.div`
  width: 300px;
  background-color: white;
  cursor: pointer;
  color: black;

  // height: 250px;
  // border-radius: 5px;
  // border: 1px solid black;
  // position: relative;
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     bottom: -40px;
  //     left: 0;
  //     border: 20px solid black;
  //     border-color: white transparent transparent transparent;
  //     border-radius: 5px;
  //   }
`;
const StyledInfoModal = styled.div`
  padding: 10px;
`;

export const MapInfoWindow = ({ info }) => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <StyledInfoWindow
        onClick={() => {
          setShowModal(true);
        }}
      >
        <h4>{info?.type?.toUpperCase()} Alert</h4>
        <ul className="d-flex flex-column gap-2 mt-3">
          <li>Detection Time : {info.start || info.detectionTime}</li>
          <li>Device Id : {info.device?.devId}</li>
          <li>
            Location :
            {`${capitalizeFirstCharacter(
              info.daira
            )} , ${capitalizeFirstCharacter(info.wilaya.name)} `}
          </li>
          <li>{` Coordinates: [ ${info.location?.coordinates[0]} , ${info.location?.coordinates[1]} ] `}</li>
        </ul>

        <img
          src={info.photo?.normal}
          alt="Alert"
          style={{
            height: "150px",
            width: "100%",
          }}
        />
      </StyledInfoWindow>
      {showModal && (
        <Modal isOpen={showModal} toggle={toggle} centered size="lg">
          <ModalHeader toggle={toggle} className="p-2">
            {info.type?.toUpperCase()} Alert
          </ModalHeader>

          <StyledInfoModal>
            <ul className="d-flex flex-wrap gap-2">
              <li className="w-50">
                Detection Time : {info.start || info.detectionTime}
              </li>
              <li className="w-50">Device Id : {info.device?.devId}</li>
              <li className="w-50">
                Location :
                {`${capitalizeFirstCharacter(
                  info.daira
                )} , ${capitalizeFirstCharacter(info.wilaya.name)} `}
              </li>
              <li className="w-50">{` Coordinates: [ ${info.location?.coordinates[0]} , ${info.location?.coordinates[1]} ] `}</li>
            </ul>

            <img
              src={info.photo?.normal}
              alt="Alert"
              style={{
                maxHeight: "300px",
                width: "100%",
              }}
            />
          </StyledInfoModal>
        </Modal>
      )}
    </>
  );
};
