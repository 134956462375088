import React from "react";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "../stores";
import { LevelOf, pageIsAllowed } from "../Components/Common/LevelOf";
import { authProtectedRoutes } from "./allRoutes";

export const AuthProtected = (props) => {
  const user = useAuth((state) => state.user);
  const accessToken = useAuth((state) => state.accessToken);

  if (!user || !accessToken) {
    return <Navigate to={"/login"} />;
  }

  if (user && user.tempPassword === true) {
    return <Navigate to={"/reset-password"} />;
  }

  //select the redirected Page, incase the user tries accessing restricted pages
  const firstAllowedPage = authProtectedRoutes.find((route) =>
    pageIsAllowed(user, route.id)
  );

  if (pageIsAllowed(user, props.id)) {
    return <React.Fragment>{props.children}</React.Fragment>;
  } else {
    const returnLink = firstAllowedPage ? firstAllowedPage.path : "/login";

    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "black",
            color: "white",
            fontSize: "25px",
            height: "100vh",
          }}
        >
          <span>
            Unauthorized Access{" "}
            <Link
              to={returnLink}
              className="rounded-1 p-2 bg-primary text-white bx bx-left-arrow-alt"
            >
              return back
            </Link>
          </span>
        </div>
      </React.Fragment>
    );
  }
};
