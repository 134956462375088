import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";
import moment from "moment";
export const useDashboardStore = create((set, get) => ({
  dataPerPeriod: [],
  temperature: [],
  wind: [],
  updatedAt: new Date().getTime(),

  isLoading: false,
  periodIsLoading: false,
  isError: false,
  error: undefined,
  filters: null,
  resultsCount: undefined,
  // Methods
  getTempPerPeriod: async (body) => {
    try {
      set({ periodIsLoading: true });
      if (body.selectedDates.length !== 2 || !body.interval) {
        throw new Error("invalid arguments");
      }

      // // Loop through all dates between start and end date, inclusive
      // while (startDate <= endDate) {
      //   dateArray.push(startDate.format("YYYY-MM-DD"));
      //   startDate.add(1, "days"); // Move to the next day
      // }

      // Now, you have an array of all dates between selectedDates
      // You can use these dates as arguments for your function
      let bodyforPeriod = {
        interval: body.interval,
        dateFrom: body.selectedDates[0],
        dateTo: body.selectedDates[1],
      };

      let response = await axiosHelper.post(
        "/system/temp/period",
        bodyforPeriod
      );
      if (response.result) {
        // data.push(response.data);
      }

      set({
        dataPerPeriod: response.data,
        periodIsLoading: false,
      });
      set({ updatedAt: new Date().getTime() });
    } catch (error) {
      console.log(error);
    } finally {
      set({ periodIsLoading: false });
    }
  },

  getTemperature: async (body) => {
    try {
      set({ isLoading: true });
      let response = await axiosHelper.post("/system/avgtemp/dates", body);

      if (!response.result) {
        return;
      }
      // console.log(response.data);

      set({
        temperature: response.data,
        resultsCount: response?.count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  getWind: async (body) => {
    try {
      set({ isLoading: true });

      let response = await axiosHelper.post("/system/avgwind/dates", body);

      if (!response.result) {
        return;
      }

      set({
        wind: response.data,
        resultsCount: response?.count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
