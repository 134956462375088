/* eslint-disable no-debugger */
/* global google */

import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";
import NormalDeviceIcon from "../../assets/imgs/normal-device.svg";
import DeviceHasIssuesIcon from "../../assets/imgs/device-has-issues.svg";
import { MapInfoWindow } from "./MapInfoWindow";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDevicesStore } from "./../../stores/Devices";
import FeatherIcon from "feather-icons-react";
import { t } from "i18next";

export const DevicesMapview = () => {
  const title = "Map View";
  const mapRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [locationTitle, setLocationTitle] = useState("Devices");

  const [isCenter, setIsCenter] = useState(true);

  const [infoWindowData, setInfoWindowData] = useState();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  // API Call
  const { getDevices, isLoading, isError, devices, deleteDevice } =
    useDevicesStore((state) => state);

  const defaultProps = {
    center: {
      lat: 30.0444,
      lng: 31.2357,
    },
    algeria: {
      lat: 36.37377988878743,
      lng: 3.8951729813480367,
    },
    zoom: 9,
  };

  const [markers, setMarkers] = useState([]);

  const fitBoundsHandler = () => {
    if (!markers.length) return;
    const map = mapRef.current;
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach((marker) =>
      bounds.extend({
        lat: marker?.location?.coordinates[0],
        lng: marker?.location?.coordinates[1],
      })
    );

    // map.fitBounds(bounds);
  };
  const handleMarkerClick = (markerData) => {
    // mapRef?.panTo({ lat, lng });
    setInfoWindowData(markerData);
    setIsOpen(true);
    setIsCenter(false); // So after this the center is not Algeria
  };

  useEffect(() => {
    getDevices();
  }, []);

  useEffect(() => {
    if (!devices?.length) return;
    setMarkers(devices);
  }, [devices]);

  // useEffect(() => {
  //   // fitBoundsHandler();
  // }, [markers]);
  const [mapType, setMapType] = useState("roadmap");
  const toggleMapType = () => {
    if (mapType === "roadmap") {
      setMapType("satellite");
    } else {
      setMapType("roadmap");
    }
  };
  if (!isLoaded || isLoading) {
    return <h1>Loading...</h1>;
  }
  const handleCenter = () => {
    if (!isCenter) {
      setIsCenter(true);
      setLocationTitle("Devices");
    } else {
      setIsCenter(false);
      setLocationTitle("Center");
      fitBoundsHandler();
    }
  };
  return (
    <React.Fragment>
      <BreadCrumb
        title={title}
        handleCenter={handleCenter}
        locationTitle={locationTitle}
        toggleMapType={toggleMapType}
      />

      <div style={{ height: "70vh", width: "100%" }}>
        <GoogleMap
          mapContainerClassName="w-100 h-100"
          center={
            isCenter
              ? defaultProps.algeria
              : {
                  lat: infoWindowData?.location?.coordinates[0],
                  lng: infoWindowData?.location?.coordinates[1],
                }
          }
          zoom={defaultProps.zoom}
          // onLoad={fitBoundsHandler}
          onClick={() => setIsOpen(false)}
          scaledSize={new google.maps.Size(0, 0.1)}
          mapTypeId={mapType}
          ref={mapRef}
        >
          {markers.map((markerData) => (
            <Marker
              key={markerData._id}
              icon={{
                scaledSize: new google.maps.Size(30, 30),
                origin: new google.maps.Point(0, 0),
                ...(markerData.status === "online"
                  ? { url: NormalDeviceIcon }
                  : { url: DeviceHasIssuesIcon }),
              }}
              position={{
                lat: markerData.location?.coordinates[0],
                lng: markerData.location?.coordinates[1],
              }}
              onClick={() => {
                handleMarkerClick(markerData);
              }}
            >
              {isOpen && infoWindowData?._id === markerData._id && (
                <InfoWindow
                  onCloseClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <MapInfoWindow info={markerData} />
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      </div>
    </React.Fragment>
  );
};
