export function LevelOf(user) {
  const roles = user.roles;

  if (roles.includes("superAdmin")) return 0;

  if (roles.includes("admin")) return 1;
  if (roles.includes("operator", "user")) return 2;
}

export function pageIsAllowed(user, page) {
  const pagesAllowed = user?.pages || ["*"];

  return pagesAllowed.includes("*") || pagesAllowed.includes(page);
}

export const getFirstAccessibleRoute = (user, routes) => {
  for (let route of routes) {
    if (pageIsAllowed(user, route.id)) {
      return route.path;
    }
  }
  return "/"; // default fallback
};
