import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LevelOf,
  pageIsAllowed,
  PageIsAllowed,
} from "../Components/Common/LevelOf";
import { useAuth } from "../stores";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [isAlerts, setIsAlerts] = useState(false);
  const [isDevices, setIsDevices] = useState(false);
  const [isStaffs, setIsStaffs] = useState(false);

  const user = useAuth((state) => state.user);

  // const [isVisitors, setIsVisitors] = useState(false);
  // const [isVisitorsPasses, setIsVisitorsPasses] = useState(false);
  // const [isMaintenance, setIsMaintenance] = useState(false);
  // const [isVenues, setIsVenues] = useState(false);

  const [isCurrentState, setIsCurrentState] = useState("Dashboards");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    if (isCurrentState !== "Dashboard") {
      setIsDashboard(false);
    }

    if (isCurrentState !== "Monitoring") {
      setIsMonitoring(false);
    }
    if (isCurrentState !== "Alerts") {
      setIsAlerts(false);
    }
    if (isCurrentState !== "Devices") {
      setIsDevices(false);
    }
    if (isCurrentState !== "Staffs") {
      setIsStaffs(false);
    }
    // if (isCurrentState !== "Facilities") {
    //   setIsFacilities(false);
    // }
    // if (isCurrentState !== "Visitors") {
    //   setIsVisitors(false);
    // }
    // if (isCurrentState !== "VisitorsPasses") {
    //   setIsVisitorsPasses(false);
    // }
    // if (isCurrentState !== "Maintenance") {
    //   setIsMaintenance(false);
    // }
    // if (isCurrentState !== "Staffs") {
    //   setIsStaffs(false);
    // }
    // if (isCurrentState !== "Venues") {
    //   setIsVenues(false);
    // }
  }, [isCurrentState, history]);

  const menuItems = [
    {
      label: t("Menu"),
      isHeader: true,
      level: 3,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "",
      level: 1,
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIsCurrentState("Dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "dashboard1",
          label: t("Principal"),
          link: "/dashboard",
          parentId: "dashboard",
          level: 1,
        },
        {
          id: "dashboard2",
          label: t("Cartes"),
          link: "/dashboard/2",
          parentId: "dashboard",
          level: 1,
        },
        {
          id: "dashboard3",
          label: t("FDI and 30%"),
          link: "/dashboard/3",
          parentId: "dashboard",
          level: 1,
        },
        {
          id: "dashboard4",
          label: t("FDI Heatmap"),
          link: "/dashboard/4",
          parentId: "dashboard",
          level: 1,
        },
      ],
    },

    {
      id: "monitoring",
      label: "Monitoring",
      icon: "ri-map-pin-line",
      link: "",
      level: 1,
      stateVariables: isMonitoring,
      click: function (e) {
        e.preventDefault();
        setIsMonitoring(!isMonitoring);
        setIsCurrentState("Monitoring");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "map-view",
          label: "Map View",
          link: "/monitoring/map-view",
          parentId: "monitoring",
          level: 1,
        },
      ],
    },
    {
      id: "Alerts",
      label: "Rapports",
      icon: "ri-error-warning-line",
      link: "",
      level: 1,
      stateVariables: isAlerts,
      click: function (e) {
        e.preventDefault();
        setIsAlerts(!isAlerts);
        setIsCurrentState("Alerts");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "map-view",
          label: "Alertes",
          link: "/alerts",
          parentId: "alerts",
          level: 1,
        },
      ],
    },
    {
      id: "devices",
      label: "Devices",
      icon: "ri-computer-line",
      link: "",
      level: 1,
      stateVariables: isDevices,
      click: function (e) {
        e.preventDefault();
        setIsDevices(!isDevices);
        setIsCurrentState("Devices");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "devices-list",
          label: "Devices List",
          link: "/devices/list",
          parentId: "devices",
          level: 1,
        },
        {
          id: "devices-map",
          label: "Map View",
          link: "/devices/map",
          parentId: "devices",
          level: 1,
        },
        {
          id: "devices-monitor",
          label: "Device Monitor",
          link: "/devices/monitor",
          parentId: "devices",
          level: 0,
        },
      ],
    },
    {
      id: "Staffs",
      label: "Staffs",
      level: 0,
      icon: "ri-group-line",
      link: "",
      stateVariables: isStaffs,
      click: function (e) {
        e.preventDefault();
        setIsStaffs(!isStaffs);
        setIsCurrentState("Staffs");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "staffs",
          label: "Staffs",
          link: "/staffs",
          parentId: "Staffs",
          level: 0,
        },
      ],
    },
  ];

  let authorizedMenuItems = menuItems
    .slice(1)
    .map((item) => {
      const filteredSubItems = item.subItems
        ? item.subItems.filter((subItem) =>
            pageIsAllowed(user, subItem.id.toLowerCase())
          )
        : [];

      if (
        filteredSubItems.length > 0 ||
        pageIsAllowed(user, item.id.toLowerCase())
      ) {
        return { ...item, subItems: filteredSubItems };
      }
      return null;
    })
    .filter(Boolean);

  authorizedMenuItems.unshift(menuItems[0]); // Add the first item back to the array

  return <React.Fragment>{authorizedMenuItems}</React.Fragment>;
};
export default Navdata;
