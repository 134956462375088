//useNotificationStore

import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";

export const useNotificationStore = create((set, get) => ({
  notifications: [],
  isLoading: false,
  isError: false,
  error: undefined,
  filters: null,
  resultsCount: undefined,
  // Methods
  fetchNotification: async (filters) => {
    // set({ filters: filterNonFalseValues(filters) });
    //
    set({ filters: filterNonFalseValues(filters) });
    const body = {
      ...filterNonFalseValues(filters),
      // search: search,
      sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      page: filters?.page || 1,
      limit: filters?.perPage || 10,
    };
    //
    try {
      set({ isLoading: true });
      let response = await axiosHelper.post("/system/notification/get", body);

      if (!response.result) {
        return;
      }
      //
      set({
        notifications: response.data,
        resultsCount: response.data.length,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
}));
